import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import {
  DATETIME_FORMAT_ISO,
  DATE_FORMAT_ISO,
  DATE_FORMAT_WEB,
  DATE_NO_DAY_FORMAT_WEB,
} from 'Constants/dateFormatConstants';
import { preferences_for_summer_options, customer_nationalities } from 'Constants/selectOptionsConstants';
import { SummerDestinations } from 'Handlers/BeachDropDownsHandler';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { SummerChangeRoleJobTitle } from 'Handlers/BeachDropDownsHandler';

const RepSummerForm = (props) => {
  const {
    repWorkApplication,
    setRepWorkApplication,

    repWorkApplicationError,
    setRepWorkApplicationError,

    isWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const checkForDuplicateDestinations = (newChoiceDestination) => {
    // Create an array with all destinations
    const destinations = [
      newChoiceDestination,
      repWorkApplication.repSummerForm.firstChoiceDestination,
      repWorkApplication.repSummerForm.secondChoiceDestination,
      repWorkApplication.repSummerForm.thirdChoiceDestination,
      repWorkApplication.repSummerForm.fourthChoiceDestination,
    ].filter((destination) => destination && destination.trim() !== '');

    // Use a Set to remove duplicates and compare its size to the original array
    const uniqueDestinations = new Set(destinations);

    if (uniqueDestinations.size < destinations.length) {
      // If the size of the Set is less than the original array, duplicates were removed
      toastr.warning('Warning: You have selected duplicate destinations.');
      return true; // Indicates duplicates were found
    }

    return false; // Indicates no duplicates were found
  };

  const cardStyle = {
    marginTop: '1%',
  };

  return (
    <div
      hidden={
        repWorkApplication.repSummerForm.preferencesFor ===
        'I do not wish to work in summer and plan to resign at the end of summer'
      }
    >
      <h2 style={cardStyle}>Summer</h2>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-sun-o mr-2" />
          For Summer (April-October) season I wish to work in the following destinations
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>My preference for summer work are {requiredField()}</Form.Label>
                <Select
                  options={preferences_for_summer_options}
                  value={
                    preferences_for_summer_options.find(
                      (option) => option.value === repWorkApplication.repSummerForm.preferencesFor
                    ) || null
                  }
                  onChange={(e) => {
                    // reset all fields
                    if (!e) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {},
                        repSummerForm: {},
                        repBeachForm: {},
                      });
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repSummerForm: {
                          ...repWorkApplicationError.repSummerForm,
                          preferencesFor: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repSummerForm: {
                          ...repWorkApplicationError.repSummerForm,
                          preferencesFor: null,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repSummerForm.preferencesFor && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.preferencesFor}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                lg={3}
                hidden={
                  repWorkApplication.repSummerForm.preferencesFor !== 'Would like to work part summer only' ||
                  !repWorkApplication.repSummerForm.preferencesFor
                }
              >
                <Form.Label>My preference for summer start month {requiredField()}</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_NO_DAY_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_NO_DAY_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(
                    repWorkApplication.repSummerForm.preferenceStartMonth,
                    DATETIME_FORMAT_ISO,
                    DATE_NO_DAY_FORMAT_WEB
                  )}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        preferenceStartMonth: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repSummerForm: {
                        ...repWorkApplicationError.repSummerForm,
                        preferenceStartMonth: null,
                      },
                    });
                  }}
                  isValidDate={(currentDate) => {
                    const startOfPeriod = moment(new Date(2025, 4, 1));
                    const endOfPeriod = moment(new Date(2025, 10, 30));
                    return currentDate.isSameOrAfter(startOfPeriod) && currentDate.isSameOrBefore(endOfPeriod);
                  }}
                />
                {repWorkApplicationError.repSummerForm.preferenceStartMonth && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.preferenceStartMonth}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                lg={3}
                hidden={
                  repWorkApplication.repSummerForm.preferencesFor !== 'Would like to work part summer only' ||
                  !repWorkApplication.repSummerForm.preferencesFor
                }
              >
                <Form.Label>My preference for summer end month {requiredField()}</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_NO_DAY_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_NO_DAY_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(
                    repWorkApplication.repSummerForm.preferenceEndMonth,
                    DATETIME_FORMAT_ISO,
                    DATE_NO_DAY_FORMAT_WEB
                  )}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        preferenceEndMonth: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repSummerForm: {
                        ...repWorkApplicationError.repSummerForm,
                        preferenceEndMonth: null,
                      },
                    });
                  }}
                  isValidDate={(currentDate) => {
                    const startOfPeriod = moment(new Date(2025, 4, 1));
                    const endOfPeriod = moment(new Date(2025, 10, 30));
                    return currentDate.isSameOrAfter(startOfPeriod) && currentDate.isSameOrBefore(endOfPeriod);
                  }}
                />
                {repWorkApplicationError.repSummerForm.preferenceEndMonth && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.preferenceStartMonth}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>First choice destination {requiredField()}</Form.Label>
                <Select
                  options={SummerDestinations}
                  value={
                    SummerDestinations.find(
                      (option) => option.value === repWorkApplication.repSummerForm.firstChoiceDestination
                    ) || null
                  }
                  onChange={(e) => {
                    if (checkForDuplicateDestinations(e?.value)) {
                      return null;
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repSummerForm: {
                          ...repWorkApplication.repSummerForm,
                          firstChoiceDestination: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repSummerForm: {
                          ...repWorkApplicationError.repSummerForm,
                          firstChoiceDestination: null,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repSummerForm.firstChoiceDestination && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.firstChoiceDestination}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Second choice destination {requiredField()}</Form.Label>
                <Select
                  options={SummerDestinations}
                  value={
                    SummerDestinations.find(
                      (option) => option.value === repWorkApplication.repSummerForm.secondChoiceDestination
                    ) || null
                  }
                  onChange={(e) => {
                    if (checkForDuplicateDestinations(e?.value)) {
                      return null;
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repSummerForm: {
                          ...repWorkApplication.repSummerForm,
                          secondChoiceDestination: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repSummerForm: {
                          ...repWorkApplicationError.repSummerForm,
                          secondChoiceDestination: null,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repSummerForm.secondChoiceDestination && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.secondChoiceDestination}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Third choice destination</Form.Label>
                <Select
                  options={SummerDestinations}
                  value={
                    SummerDestinations.find(
                      (option) => option.value === repWorkApplication.repSummerForm.thirdChoiceDestination
                    ) || null
                  }
                  onChange={(e) => {
                    if (checkForDuplicateDestinations(e?.value)) {
                      return null;
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repSummerForm: {
                          ...repWorkApplication.repSummerForm,
                          thirdChoiceDestination: e?.value,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Fourth choice destination</Form.Label>
                <Select
                  options={SummerDestinations}
                  value={
                    SummerDestinations.find(
                      (option) => option.value === repWorkApplication.repSummerForm.fourthChoiceDestination
                    ) || null
                  }
                  onChange={(e) => {
                    if (checkForDuplicateDestinations(e?.value)) {
                      return null;
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repSummerForm: {
                          ...repWorkApplication.repSummerForm,
                          fourthChoiceDestination: e?.value,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-sun-o mr-2" />
          <i className="fa fa-user mr-2" />
          Personal preferences
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  What customer nationalities are you willing and able to service with your language skills?{' '}
                  {requiredField()}
                </Form.Label>
                <Select
                  isMulti
                  options={customer_nationalities}
                  onChange={(selectedOptions) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        customerNationalities: selectedOptions ? selectedOptions.map((option) => option.value) : [],
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repSummerForm: {
                        ...repWorkApplicationError.repSummerForm,
                        customerNationalities: null,
                      },
                    });
                  }}
                  value={
                    (repWorkApplication.repSummerForm.customerNationalities || []).map((nat) =>
                      customer_nationalities.find((option) => option.value === nat)
                    ) || []
                  }
                  isDisabled={isWorkApplicationSubmitted}
                />

                {repWorkApplicationError.repSummerForm.customerNationalities && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.customerNationalities}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  Are you willing to complete a visa application process if required for your placement wish? (Summer){' '}
                  {requiredField()}
                </Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      value="Yes"
                      checked={repWorkApplication.repSummerForm.willingCompleteVisaApplication === 'Yes'}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repSummerForm: {
                            ...repWorkApplication.repSummerForm,
                            willingCompleteVisaApplication: e.currentTarget.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repSummerForm: {
                            ...repWorkApplicationError.repSummerForm,
                            willingCompleteVisaApplication: null,
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      value="No"
                      checked={repWorkApplication.repSummerForm.willingCompleteVisaApplication === 'No'}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repSummerForm: {
                            ...repWorkApplication.repSummerForm,
                            willingCompleteVisaApplication: e.currentTarget.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repSummerForm: {
                            ...repWorkApplicationError.repSummerForm,
                            willingCompleteVisaApplication: null,
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSummerForm.willingCompleteVisaApplication && (
                      <Form.Text className="form-text-red">
                        {repWorkApplicationError.repSummerForm.willingCompleteVisaApplication}
                      </Form.Text>
                    )}
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Expiration date of your visa (if you have one) (Summer)</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(repWorkApplication.repSummerForm.expiringVisaDate, null, DATE_FORMAT_WEB)}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        expiringVisaDate: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  If you are in a relationship and would like to request to be planned in the same Destination as your
                  partner, please confirm your partners full name (as per their Passport) in the box below{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={repWorkApplication.repSummerForm.fullPartnerName || ''}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        fullPartnerName: e.currentTarget.value,
                      },
                    });
                  }}
                  disabled={isWorkApplicationSubmitted}
                />
                <Form.Text className="text-muted">
                  Fullname ( as per passport ) of partner if you request to work together
                </Form.Text>
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-sun-o mr-2" />
          <i className="fa fa-repeat mr-2" />
          Change of Role Request
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>I would like to be considered for a change of role for summer {requiredField()}</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      inline
                      label="Yes"
                      type="radio"
                      value="Yes"
                      checked={repWorkApplication.repSummerForm.considerChangeRole === 'Yes'}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repSummerForm: {
                            ...repWorkApplication.repSummerForm,
                            considerChangeRole: e.currentTarget.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repSummerForm: {
                            ...repWorkApplicationError.repSummerForm,
                            considerChangeRole: null,
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type="radio"
                      value="No"
                      checked={repWorkApplication.repSummerForm.considerChangeRole === 'No'}
                      onChange={(e) => {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repSummerForm: {
                            ...repWorkApplication.repSummerForm,
                            considerChangeRole: e.currentTarget.value,
                            considerChangeRoleJobTitles: [],
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repSummerForm: {
                            ...repWorkApplicationError.repSummerForm,
                            considerChangeRole: null,
                          },
                        });
                      }}
                      disabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSummerForm.considerChangeRole && (
                      <Form.Text className="form-text-red">
                        {repWorkApplicationError.repSummerForm.considerChangeRole}
                      </Form.Text>
                    )}
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                as={Col}
                hidden={
                  repWorkApplication.repSummerForm.considerChangeRole === 'No' ||
                  !repWorkApplication.repSummerForm.considerChangeRole
                }
              >
                <Form.Label>In summer (multiple selection)</Form.Label>
                <Select
                  isMulti
                  options={SummerChangeRoleJobTitle}
                  onChange={(selectedOptions) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repSummerForm: {
                        ...repWorkApplication.repSummerForm,
                        considerChangeRoleJobTitles: selectedOptions.map((option) => option.value),
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repSummerForm: {
                        ...repWorkApplicationError.repSummerForm,
                        considerChangeRoleJobTitles: null,
                      },
                    });
                  }}
                  value={
                    (repWorkApplication.repSummerForm.considerChangeRoleJobTitles || []).map((jobTitle) =>
                      SummerChangeRoleJobTitle.find((option) => option.value === jobTitle)
                    ) || []
                  }
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repSummerForm.considerChangeRoleJobTitles && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repSummerForm.considerChangeRoleJobTitles}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RepSummerForm;
