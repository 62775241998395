import { combineReducers } from 'redux';
import { reducer as ToastrReducer } from 'react-redux-toastr';

import CurrentUserReducer from 'Redux/Reducers/User/CurrentUserReducer';
import EnvironmentReducer from 'Redux/Reducers/Environment/EnvironmentReducer';
import LoadingReducer from 'Redux/Reducers/Loading/LoadingReducer';
import MasterDataReducer from 'Redux/Reducers/MasterData/MasterDataReducer';
import SettingReducer from 'Redux/Reducers/Setting/SettingReducer';

export default combineReducers({
  currentUser: CurrentUserReducer,
  environment: EnvironmentReducer,
  masterData: MasterDataReducer,
  settings: SettingReducer,
  loader: LoadingReducer,
  toastr: ToastrReducer,
});
