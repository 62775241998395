import RestClient from 'Services/RestClientService';
import { ActionTypes as types } from 'Constants/masterDataConstants';

export function getSourceMarkets() {
  const getSourceMarketSuccess = (sourceMarkets) => {
    return {
      type: types.GET_SOURCEMARKETS_SUCCESS,
      data: { sourceMarkets },
    };
  };

  return async function (dispatch) {
    const sourceMarkets = await RestClient.Get('masterdata/source-market');
    const sourceMarketsMaped = Object.keys(sourceMarkets.masterDataList ?? []).map((key) => {
      return { value: sourceMarkets.masterDataList[key], label: sourceMarkets.masterDataList[key] };
    });
    dispatch(getSourceMarketSuccess(sourceMarketsMaped));
  };
}

export function getJobFamilies() {
  const getJobFamiliesSuccess = (jobFamilies) => {
    return {
      type: types.GET_JOBFAMILIES_SUCCESS,
      data: { jobFamilies },
    };
  };

  return async function (dispatch) {
    const response = await fetch('/mappings.json');
    const data = await response.json();

    dispatch(getJobFamiliesSuccess(data));
  };
}

export function getJobTitles() {
  const getjobTitlesSuccess = (jobTitles) => {
    return {
      type: types.GET_JOBTITLES_SUCCESS,
      data: { jobTitles },
    };
  };

  return async function (dispatch) {
    const jobTitles = await RestClient.Get('masterdata/job-title');
    const jobTitlesMaped = Object.keys(jobTitles.masterDataList ?? []).map((key) => {
      return { value: jobTitles.masterDataList[key], label: jobTitles.masterDataList[key] };
    });
    dispatch(getjobTitlesSuccess(jobTitlesMaped));
  };
}

export function getPositionTypes() {
  const getPositionTypesSuccess = (positionTypes) => {
    return {
      type: types.GET_POSITION_TYPES_SUCCESS,
      data: { positionTypes },
    };
  };

  return async function (dispatch) {
    const positionTypes = await RestClient.Get('masterdata/mpl-position-type');
    const positionTypesMaped = Object.keys(positionTypes.masterDataList ?? []).map((key) => {
      return { value: positionTypes.masterDataList[key], label: positionTypes.masterDataList[key] };
    });
    dispatch(getPositionTypesSuccess(positionTypesMaped));
  };
}

export function getSeaons() {
  const getSeaonsSuccess = (seasons) => {
    return {
      type: types.GET_SEASONS_SUCCESS,
      data: { seasons },
    };
  };

  return async function (dispatch) {
    const seasons = await RestClient.Get('masterdata/season');

    const seasonsMaped = Object.keys(seasons.masterDataList ?? []).map((key) => {
      return { value: seasons.masterDataList[key], label: seasons.masterDataList[key] };
    });
    dispatch(getSeaonsSuccess(seasonsMaped));
  };
}

export function getCountries() {
  const getCountriesSuccess = (countries) => {
    return {
      type: types.GET_COUNTRIES_SUCCESS,
      data: { countries },
    };
  };

  return async function (dispatch) {
    const countries = await RestClient.Get('masterdata/country');
    const countriesMaped = Object.keys(countries.masterDataList ?? []).map((key) => {
      return { value: countries.masterDataList[key], label: countries.masterDataList[key] };
    });
    dispatch(getCountriesSuccess(countriesMaped));
  };
}

export function getRegions() {
  const getRegionsSuccess = (regions) => {
    return {
      type: types.GET_REGIONS_SUCCESS,
      data: { regions },
    };
  };

  return async function (dispatch) {
    const regions = await RestClient.Get('masterdata/region');
    const countriesMaped = Object.keys(regions.masterDataList ?? []).map((key) => {
      return { value: regions.masterDataList[key], label: regions.masterDataList[key] };
    });
    dispatch(getRegionsSuccess(countriesMaped));
  };
}

export function getDestinations() {
  const getDestinationsSuccess = (destinations) => {
    return {
      type: types.GET_DESTINATIONS_SUCCESS,
      data: { destinations },
    };
  };

  return async function (dispatch) {
    const destinations = await RestClient.Get('masterdata/destination');
    const destinationsMaped = Object.keys(destinations.masterDataList ?? []).map((key) => {
      return { value: destinations.masterDataList[key], label: destinations.masterDataList[key] };
    });
    dispatch(getDestinationsSuccess(destinationsMaped));
  };
}

export function getKeyWords() {
  const getKeyWordsSuccess = (keywords) => {
    return {
      type: types.GET_KEYWORDS_SUCCESS,
      data: { keywords },
    };
  };

  return async function (dispatch) {
    const keywords = await RestClient.Get('setting/keywords');
    dispatch(getKeyWordsSuccess(keywords));
  };
}

export function getLanguages() {
  const getLanguagesSuccess = (languages) => {
    return {
      type: types.GET_LANGUAGES_SUCCESS,
      data: { languages },
    };
  };

  return async function (dispatch) {
    const languages = await RestClient.Get('masterdata/language');
    // console.log('language', languages);
    const languagesMaped = Object.keys(languages.masterDataListTupple ?? []).map((key) => {
      return { value: languages.masterDataListTupple[key][0], label: languages.masterDataListTupple[key][1] };
    });
    languagesMaped.pop();
    dispatch(getLanguagesSuccess(languagesMaped));
  };
}

export function getLanguageLevel() {
  const getLanguageLevelSuccess = (languageLevels) => {
    return {
      type: types.GET_LANGUAGE_LEVEL_SUCCESS,
      data: { languageLevels },
    };
  };

  return async function (dispatch) {
    const languageLevels = await RestClient.Get('masterdata/language-level');
    // console.log('language-level', languageLevels);
    const languageLevelMaped = Object.keys(languageLevels.masterDataListTupple ?? []).map((key) => {
      return { value: languageLevels.masterDataListTupple[key][0], label: languageLevels.masterDataListTupple[key][1] };
    });
    dispatch(getLanguageLevelSuccess(languageLevelMaped));
  };
}
