export const RepFormValidation = (
  repWorkApplication,
  repWorkApplicationError,
  setRepWorkApplicationError,
  isLongHaul
) => {
  // outputs
  // console.log(repWorkApplication);
  // console.log(repWorkApplicationError);
  // console.log(isLongHaul);

  if (isLongHaul) {
    const isValidWinterForm = validateLongHaul(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidWinterForm) return isValidWinterForm;
  } else {
    const isValidBeachForm = validateBeach(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidBeachForm) return isValidBeachForm;

    const isValidWinterForm = validateWinter(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidWinterForm) return isValidWinterForm;

    const isValidSummerForm = validateSummer(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidSummerForm) return isValidSummerForm;
  }

  const isValidGlobalForm = validateGlobal(
    repWorkApplication,
    repWorkApplicationError,
    setRepWorkApplicationError,
    isLongHaul
  );
  if (!isValidGlobalForm) return isValidGlobalForm;

  return true;
};

const validateGlobal = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repGlobalForm.location) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repGlobalForm: {
        ...repWorkApplicationError.repGlobalForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!repWorkApplication.repGlobalForm.managerName) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repGlobalForm: {
        ...repWorkApplicationError.repGlobalForm,
        managerName: 'Please select manager name',
      },
    });
    return false;
  }

  return true;
};

const validateBeach = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repBeachForm.accelerateDevelopmentProgram) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { accelerateDevelopmentProgram: 'Please select if you are enrroled in accelerate program.' },
    });
    return false;
  }

  if (!repWorkApplication.repBeachForm.currentJobFamily) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { currentJobFamily: 'Please select current job family.' },
    });
    return false;
  }

  if (!repWorkApplication.repBeachForm.currentJobTitle) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { currentJobTitle: 'Please select current job title.' },
    });
    return false;
  }

  return true;
};
const validateWinter = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repWinterForm.preferencesFor) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferencesFor: 'Please select preferences for winter' },
    });
    return false;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor ===
      'I do not wish to work in winter but would like to return for summer' ||
    repWorkApplication.repWinterForm.preferencesFor ===
      'I do not wish to work in winter and plan to resign at the end of summer'
  ) {
    return true;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repWinterForm.preferenceStartMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferenceStartMonth: 'Please select preference start month' },
    });
    return false;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repWinterForm.preferenceEndMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferenceEndMonth: 'Please select preference end month' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.firstChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { firstChoiceDestination: 'Please select first choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.secondChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { secondChoiceDestination: 'Please select second choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.customerNationalities) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { customerNationalities: 'Please select customer nationalities' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.willingCompleteVisaApplication) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { willingCompleteVisaApplication: 'Please select willing complete visa application' },
    });

    return false;
  }

  if (!repWorkApplication.repWinterForm.considerChangeRole) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { considerChangeRole: 'Please select consider change role' },
    });

    return false;
  }

  if (
    repWorkApplication.repWinterForm.considerChangeRole === 'Yes' &&
    !repWorkApplication.repWinterForm.considerChangeRoleJobTitles
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { considerChangeRoleJobTitles: 'Please select consider change role job titles' },
    });
    return false;
  }

  return true;
};
const validateSummer = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (
    repWorkApplication.repWinterForm.preferencesFor ===
    'I do not wish to work in winter and plan to resign at the end of summer'
  ) {
    return true;
  }

  if (!repWorkApplication.repSummerForm.preferencesFor) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferencesFor: 'Please select preferences for summer' },
    });
    return false;
  }

  if (
    repWorkApplication.repSummerForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repSummerForm.preferenceStartMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferenceStartMonth: 'Please select preference start month' },
    });
    return false;
  }

  if (
    repWorkApplication.repSummerForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repSummerForm.preferenceEndMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferenceEndMonth: 'Please select preference end month' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.firstChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { firstChoiceDestination: 'Please select first choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.secondChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { secondChoiceDestination: 'Please select second choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.customerNationalities) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { customerNationalities: 'Please select customer nationalities' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.willingCompleteVisaApplication) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { willingCompleteVisaApplication: 'Please select willing complete visa application' },
    });

    return false;
  }

  if (!repWorkApplication.repSummerForm.considerChangeRole) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { considerChangeRole: 'Please select consider change role' },
    });

    return false;
  }

  if (
    repWorkApplication.repSummerForm.considerChangeRole === 'Yes' &&
    !repWorkApplication.repSummerForm.considerChangeRoleJobTitles
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { considerChangeRoleJobTitles: 'Please select consider change role job titles' },
    });
    return false;
  }

  return true;
};

const validateLongHaul = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (
    !Array.isArray(repWorkApplication.repLongHaulForm.confortableSourceMarket) ||
    repWorkApplication.repLongHaulForm.confortableSourceMarket === null ||
    repWorkApplication.repLongHaulForm.confortableSourceMarket.length === 0
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { confortableSourceMarket: ['Please select confortable source market'] },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.passportExpiry) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { passportExpiry: ' Please select passport expiry' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.currentJobFamily) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { currentJobFamily: 'Please select current job family' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.currentJobTitle) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { currentJobTitle: 'Please select current job title' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationOne) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationOne: 'Please select destination one' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationTwo) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationTwo: 'Please select destination two' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationThree) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationThree: 'Please select destination three' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationFour) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationFour: 'Please select destination four' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.whyYouSuitable) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { whyYouSuitable: 'Please enter why you are suitable' },
    });
    return false;
  }

  return true;
};
