import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

export function getEnvironmentVariables() {
  function getTppWebVersionSuccess(environmentVariables) {
    return {
      type: 'GET_TPP_WEB_ENVIRONMENT_SUCCESS',
      data: { environmentVariables },
    };
  }

  return async function (dispatch) {
    try {
      dispatch(getTppWebVersionSuccess(getEnvironmentVariablesHandler()));
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    }
  };
}
