import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';

import { DATETIME_FORMAT_ISO, DATE_FORMAT_WEB } from '../../Constants/dateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const EmployeeContactInfoCard = (props) => {
  // console.log('EmployeeContactInfoCard', props);
  const { employee, setEmployee } = props;

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-phone mr-2"></i>Contact Information
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" defaultValue={employee.title} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Nationality</Form.Label>
              <Form.Control type="text" defaultValue={employee.nat} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Date of birth</Form.Label>
              <Datetime
                onChange={(e) => {
                  setEmployee({ ...employee, dateOfBirth: e._d });
                }}
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                value={DateTimeFormater(employee.dateOfBirth, DATETIME_FORMAT_ISO, DATE_FORMAT_WEB)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Staff Age</Form.Label>
              <Form.Control
                type="text"
                value={
                  moment().diff(
                    DateTimeFormater(employee.dateOfBirth, DATETIME_FORMAT_ISO, DATETIME_FORMAT_ISO),
                    'years'
                  ) > 99
                    ? 'Can´t calculate'
                    : moment()
                        .diff(DateTimeFormater(employee.dateOfBirth, DATETIME_FORMAT_ISO, DATETIME_FORMAT_ISO), 'years')
                        .toString()
                }
                readOnly
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Gender</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Male"
                    name="gender_radio_button"
                    type="radio"
                    id="male_radio_button"
                    value="Male"
                    checked={employee.gender?.toLowerCase() === 'male'}
                    onChange={(e) => {
                      setEmployee({ ...employee, gender: e.target.value });
                    }}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Female"
                    name="gender_radio_button"
                    type="radio"
                    id="female_radio_button"
                    value="Female"
                    checked={employee.gender?.toLowerCase() === 'female'}
                    onChange={(e) => {
                      setEmployee({ ...employee, gender: e.target.value });
                    }}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Unknow"
                    name="gender_radio_button"
                    type="radio"
                    id="unknow_radio_button"
                    value="Unknow"
                    checked={employee.gender?.toLowerCase() === 'unknow'}
                    onChange={(e) => {
                      setEmployee({ ...employee, gender: e.target.value });
                    }}
                    disabled
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" defaultValue={employee.firstName} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label style={{ color: 'grey' }}>Legal First Name</Form.Label>
              <Form.Control type="text" defaultValue={employee.legalFirstName} readOnly />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" defaultValue={employee.lastName} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label style={{ color: 'grey' }}>Legal Last Name</Form.Label>
              <Form.Control type="text" defaultValue={employee.legalLastName} readOnly />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="phone"
                defaultValue={employee.phone}
                onChange={(e) => {
                  setEmployee({ ...employee, phone: e.target.value });
                }}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Phone Home</Form.Label>
              <Form.Control
                type="phone"
                defaultValue={employee.phoneHome}
                onChange={(e) => {
                  setEmployee({ ...employee, phoneHome: e.target.value });
                }}
                disabled
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Nationality 2</Form.Label>
              <Form.Control type="text" defaultValue={employee.nat2} readOnly />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Home airport</Form.Label>
              <Form.Control type="text" defaultValue={employee.homeAirport} readOnly />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Work Email</Form.Label>
              <Form.Control type="email" defaultValue={employee.emailWork} readOnly />
              <Form.Text className="text-muted">(deprecated)</Form.Text>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Private Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={employee.emailPrivate}
                onChange={(e) => {
                  setEmployee({ ...employee, emailPrivate: e.target.value });
                }}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" defaultValue={employee.email} readOnly />
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EmployeeContactInfoCard;
