import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const TppNavBar = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const settings = useSelector((state) => state.settings);

  const checkActiveNavBarLink = (activeLink) => {
    if (window.location.pathname.includes(activeLink)) return true;
    return false;
  };
  return (
    <React.Fragment>
      <Nav variant="pills" style={{ marginLeft: '25%' }}>
        <Nav.Item>
          <Nav.Link href="/mtp/profile" active={checkActiveNavBarLink('/mtp/profile')}>
            Profile
          </Nav.Link>
        </Nav.Item>
        {(settings.applyOpen === 'Yes' || settings.isDiamondPin) && (
          <Nav.Item>
            <Nav.Link href="/mtp/rep-work-application" active={checkActiveNavBarLink('/mtp/rep-work-application')}>
              Application Form
            </Nav.Link>
          </Nav.Item>
        )}

        {(settings.acceptDeclineOpen === 'Yes' || settings.isDiamondPin) && (
          <Nav.Item>
            <Nav.Link href="/mtp/assigments" active={checkActiveNavBarLink('/mtp/assigments')}>
              Assigments
            </Nav.Link>
          </Nav.Item>
        )}

        {currentUser.isManager && settings.commentsOpen === 'Yes' && (
          <Nav.Item>
            <Nav.Link href="/mtp/manage-rep" active={checkActiveNavBarLink('/mtp/manage-rep')}>
              Manage rep
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </React.Fragment>
  );
};

export default TppNavBar;
