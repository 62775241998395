import React from 'react';
import { Row } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Row style={{ justifyContent: 'center', textAlign: 'center', padding: '70px 0' }}>
      <p>
        <i className="fa fa-times" /> 404
        <small>Page not found.</small>
      </p>
    </Row>
  );
};

export default NotFound;
