export function setLoader(isLoading) {
  function setLoaderSucces(isLoading) {
    return {
      type: 'LOADER',
      data: { isLoading },
    };
  }

  return async function (dispatch) {
    dispatch(setLoaderSucces(isLoading));
  };
}
