import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
// import Select from 'react-select';

// import {
//   if_collegue_working_concept,
//   manager_colleague_strenghts_options,
//   manager_development_areas_options,
// } from 'Constants/selectOptionsConstants';

const ManagerCommentsForm = (props) => {
  const {
    managerWorkApplication,
    setManagerWorkApplication,
    managerWorkApplicationError,
    setManagerWorkApplicationError,
    isManagerWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '0.5%',
  };
  return (
    <Card style={cardStyle}>
      <Card.Header>
        <i className="fa fa-comments-o mr-2" />
        Manager questionary
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Do you validate/support the winter availability, winter destination and winter job family requests plus
                the submitted language skills? {requiredField()}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedWinterAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedWinterAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm?.isManagerValidatedWinterAvailability === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedWinterAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedWinterAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />

                  {managerWorkApplicationError.managerCommentsForm.isManagerValidatedWinterAvailability && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isManagerValidatedWinterAvailability}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              as={Col}
              hidden={managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability !== 'No'}
            >
              <Form.Label>Winter comments {requiredField()} </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerValidatedWinterAvailabilityComments: e.target.value,
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerValidatedWinterAvailabilityComments: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={managerWorkApplication.managerCommentsForm.managerValidatedWinterAvailabilityComments || ''}
                disabled={isManagerWorkApplicationSubmitted}
              />

              {managerWorkApplicationError.managerCommentsForm.managerValidatedWinterAvailabilityComments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerValidatedWinterAvailabilityComments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Do you validate/support the summer availability, summer destination and summer job family requests plus
                the submitted language skills? {requiredField()}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedSummerAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedSummerAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatedSummerAvailability: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatedSummerAvailability: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCommentsForm.isManagerValidatedSummerAvailability && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isManagerValidatedSummerAvailability}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              as={Col}
              hidden={managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability !== 'No'}
            >
              <Form.Label>Summer comments {requiredField()}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerValidatedSummerAvailabilityComments: e.target.value,
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerValidatedSummerAvailabilityComments: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={managerWorkApplication.managerCommentsForm.managerValidatedSummerAvailabilityComments || ''}
                disabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.managerValidatedSummerAvailabilityComments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerValidatedSummerAvailabilityComments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>
          {/* <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Please confirm if the colleague is enrolled in Accelerate {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          managerSupportsAdp: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          managerSupportsAdp: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.managerSupportsAdp === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          managerSupportsAdp: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          managerSupportsAdp: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.managerSupportsAdp === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />

                  <Form.Check
                    inline
                    label="Not applicable"
                    type="radio"
                    value="NotApplicable"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          managerSupportsAdp: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          managerSupportsAdp: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.managerSupportsAdp === 'NotApplicable'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCommentsForm.managerSupportsAdp && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.managerSupportsAdp}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Any Disciplinary or Performance Management in process? {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isPerformanceManagementInprocess: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isPerformanceManagementInprocess: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isPerformanceManagementInprocess === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isPerformanceManagementInprocess: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isPerformanceManagementInprocess: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isPerformanceManagementInprocess === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCommentsForm.isPerformanceManagementInprocess && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isPerformanceManagementInprocess}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Do you validate the source markets suggested by the colleague? {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatesSourceMarkets: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatesSourceMarkets: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatesSourceMarkets === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerCommentsForm: {
                          ...managerWorkApplication.managerCommentsForm,
                          isManagerValidatesSourceMarkets: e.target.value,
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });

                      setManagerWorkApplicationError({
                        managerCommentsForm: {
                          isManagerValidatesSourceMarkets: null,
                        },
                        managerWorkApplicationForm: {},
                      });
                    }}
                    checked={managerWorkApplication.managerCommentsForm.isManagerValidatesSourceMarkets === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerCommentsForm.isManagerValidatesSourceMarkets && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerCommentsForm.isManagerValidatesSourceMarkets}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              as={Col}
              hidden={managerWorkApplication.managerCommentsForm.isManagerValidatesSourceMarkets !== 'No'}
            >
              <Form.Label>Validation source market comments {requiredField()}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerValidatesSourceMarketsComments: e.target.value,
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerValidatesSourceMarketsComments: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={managerWorkApplication.managerCommentsForm.managerValidatesSourceMarketsComments}
                disabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.managerValidatesSourceMarketsComments && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerValidatesSourceMarketsComments}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="my-1 mr-2">
                If this colleague works in Concepts, please select guest profile suitability from this selection{' '}
                {requiredField()}
              </Form.Label>
              <Select
                options={if_collegue_working_concept}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      collegueWorkingConcept: e?.map((option) => option.value),
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      collegueWorkingConcept: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                isSearchable
                isClearable
                isMulti
                value={if_collegue_working_concept.filter((obj) => {
                  return managerWorkApplication.managerCommentsForm.collegueWorkingConcept?.includes(obj.value);
                })}
                isDisabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.collegueWorkingConcept && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.collegueWorkingConcept}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="my-1 mr-2">Colleague top 3 strengths {requiredField()}</Form.Label>
              <Select
                options={manager_colleague_strenghts_options}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerColleagueStrenghts: e?.map((option) => option.value),
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerColleagueStrenghts: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={manager_colleague_strenghts_options.filter((obj) => {
                  return managerWorkApplication.managerCommentsForm.managerColleagueStrenghts?.includes(obj.value);
                })}
                isDisabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.managerColleagueStrenghts && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerColleagueStrenghts}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="my-1 mr-2">Any areas of development ? {requiredField()}</Form.Label>
              <Select
                options={manager_development_areas_options}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerCommentsForm: {
                      ...managerWorkApplication.managerCommentsForm,
                      managerDevelopmenTareas: e?.map((option) => option.value),
                    },
                    managerWorkApplicationForm: {
                      ...managerWorkApplication.managerWorkApplicationForm,
                    },
                  });

                  setManagerWorkApplicationError({
                    managerCommentsForm: {
                      managerDevelopmenTareas: null,
                    },
                    managerWorkApplicationForm: {},
                  });
                }}
                value={manager_development_areas_options.filter((obj) => {
                  return managerWorkApplication.managerCommentsForm.managerDevelopmenTareas?.includes(obj.value);
                })}
                isDisabled={isManagerWorkApplicationSubmitted}
              />
              {managerWorkApplicationError.managerCommentsForm.managerDevelopmenTareas && (
                <Form.Text className="form-text-red">
                  {managerWorkApplicationError.managerCommentsForm.managerDevelopmenTareas}
                </Form.Text>
              )}
            </Form.Group>
          </Form.Row> */}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ManagerCommentsForm;
