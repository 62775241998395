import EmployeeContactInfoCard from 'Components/Cards/EmployeeContactInfoCard';
import EmployeeLanguagesCard from 'Components/Cards/EmployeeLanguagesCard';
import EmployeeWorkInfoCard from 'Components/Cards/EmployeeWorkInfoCard';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

import RestClient from 'Services/RestClientService';

const MtpStaffProfileView = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.currentUser.upn);

  const [employee, setEmployee] = useState({});

  useEffect(() => {
    if (!currentUserEmail) return;
    dispatch(setLoader(true));
    RestClient.Get(`staff/getbyemail/${currentUserEmail}`)
      .then((staffInfo) => {
        // console.log(staffInfo);
        if (!staffInfo) return;
        setEmployee((prev) => {
          return { ...prev, ...staffInfo };
        });
        document.title = `MTP - ${staffInfo.firstName} ${staffInfo.lastName}`;
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, [currentUserEmail]);

  return (
    <Container fluid>
      <h1 style={{ marginTop: '0.5%' }}>Staff Profile</h1>
      <EmployeeContactInfoCard employee={employee} />
      <EmployeeWorkInfoCard employee={employee} />
      <EmployeeLanguagesCard employee={employee} />
    </Container>
  );
};

export default MtpStaffProfileView;
