import { ActionTypes as types } from 'Constants/settingConstants';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

export function getTppSettings() {
  function getTppSettingsSuccess(settings) {
    return {
      type: types.GET_TPP_SETTINGS_SUCCESS,
      data: { settings },
    };
  }

  return async function (dispatch) {
    try {
      dispatch(setLoader(true));
      const settings = await RestClient.Get('setting/setting');
      dispatch(getTppSettingsSuccess(settings));
    } catch (error) {
      dispatch(setLoader(false));
      throw error;
    } finally {
      dispatch(setLoader(false));
    }
  };
}
