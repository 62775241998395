import React from 'react';
import { useSelector } from 'react-redux';
import { Bars } from 'react-loader-spinner';

const Loader = () => {
  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <div>
      {isLoading && (
        <div className="loading-background">
          <Bars
            height="80"
            width="80"
            color="red"
            ariaLabel="bars-loading"
            wrapperClass="loading-bar"
            zIndex="9999"
            visible={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default Loader;
