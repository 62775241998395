import { ActionTypes as types } from 'Constants/settingConstants';

var defaultState = {};

export default function settingReducer(state = defaultState, action) {
  switch (action.type) {
    case types.GET_TPP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.data.settings,
      };
    default:
      return state;
  }
}
