import { applyMiddleware, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import RootReducer from 'Redux/Reducers/RootReducer';

var logger = createLogger({
  collapsed: true,
});

const middleware =
  process.env.NODE_ENV !== 'production'
    ? //Local
      [reduxImmutableStateInvariant(), thunk, logger]
    : //Production
      [thunk];

var store = createStore(RootReducer, applyMiddleware(...middleware));

export default store;
