export const BeachGuestType = [
  { value: 'Families', label: 'Families' },
  { value: 'Adults', label: 'Adults' },
  { value: 'Couples', label: 'Couples' },
  { value: 'Big Team', label: 'Big Team' },
  { value: 'Small Team', label: 'Small Team' },
  { value: 'Independent', label: 'Independent' },
];

export const BeachInternationalConcepts = [
  { value: 'Suneo', label: 'Suneo' },
  { value: 'Sensatori', label: 'Sensatori' },
  { value: 'Scene', label: 'Scene' },
  { value: 'TUI Blue for Two', label: 'TUI Blue for Two' },
  { value: 'TUI Blue for Families', label: 'TUI Blue for Families' },
];

export const BeachNationalConcepts = [
  { value: 'Blue Star', label: 'Blue Star' },
  { value: 'Holiday Village', label: 'Holiday Village' },
  { value: 'L&M', label: 'L&M' },
  { value: 'Scene', label: 'Scene' },
  { value: 'Time to Smile', label: 'Time to Smile' },
];

export const BeachJobFamilies = [
  { label: 'Concept - Childcare', value: 'Concept - Childcare' },
  { label: 'Concept - Entertainment & Leisure', value: 'Concept - Entertainment & Leisure' },
  { label: 'Concept - Reps', value: 'Concept - Reps' },
  { label: 'Concept - Supervisors', value: 'Concept - Supervisors' },
  { label: 'Concept - Team Leaders', value: 'Concept - Team Leaders' },
  { label: 'Concept - Team Managers', value: 'Concept - Team Managers' },
  { label: 'Core - Airport', value: 'Core - Airport' },
  { label: 'Core - Other', value: 'Core - Other' },
  { label: 'Core - Reps', value: 'Core - Reps' },
  { label: 'Core - SM Paid', value: 'Core - SM Paid' },
  { label: 'Core - Team Leaders', value: 'Core - Team Leaders' },
  { label: 'Core - Team Managers', value: 'Core - Team Managers' },
];

export const BeachJobTitles = [
  { label: 'Baby Club Host', value: 'Baby Club Host' },
  { label: 'Kids Club Host', value: 'Kids Club Host' },
  { label: 'Entertainment & Activities Host', value: 'Entertainment & Activities Host' },
  { label: 'Fitness & Leisure Jumper', value: 'Fitness & Leisure Jumper' },
  { label: 'Fitness Instructor', value: 'Fitness Instructor' },
  { label: 'Football Coach', value: 'Football Coach' },
  { label: 'Swim Coach', value: 'Swim Coach' },
  { label: 'SENSATORI Expert', value: 'SENSATORI Expert' },
  { label: 'Kids Club Supervisor', value: 'Kids Club Supervisor' },
  { label: 'BLUE Guide Team Leader', value: 'BLUE Guide Team Leader' },
  { label: 'Childcare Delivery Team Leader', value: 'Childcare Delivery Team Leader' },
  { label: 'Entertainment & Activities Team Leader', value: 'Entertainment & Activities Team Leader' },
  { label: 'Holiday Village Academy Team Leader', value: 'Holiday Village Academy Team Leader' },
  { label: 'BLUE Guide Manager', value: 'BLUE Guide Manager' },
  { label: 'Childcare Delivery Team Manager', value: 'Childcare Delivery Team Manager' },
  { label: 'Entertainment Delivery Team Manager', value: 'Entertainment Delivery Team Manager' },
  { label: 'Holiday Village Delivery Manager', value: 'Holiday Village Delivery Manager' },
  { label: 'Airport Rep', value: 'Airport Rep' },
  { label: 'Excursion Rep', value: 'Excursion Rep' },
  { label: 'Quality Assurance Coordinator', value: 'Quality Assurance Coordinator' },
  { label: 'The Mora Experience Expert', value: 'The Mora Experience Expert' },
  { label: 'BLUE Guide', value: 'BLUE Guide' },
  { label: 'Experiences Expert', value: 'Experiences Expert' },
  { label: 'Hotel Rep', value: 'Hotel Rep' },
  { label: 'Hotel Rep (L&M)', value: 'Hotel Rep (L&M)' },
  { label: 'Service Support Rep', value: 'Service Support Rep' },
  { label: 'Service Support Rep (L&M)', value: 'Service Support Rep (L&M)' },
  { label: 'Blue Star Service & Activities Host', value: 'Blue Star Service & Activities Host' },
  { label: 'TIME TO SMILE Service Host', value: 'TIME TO SMILE Service Host' },
  { label: 'Airport Team Leader', value: 'Airport Team Leader' },
  { label: 'Resort Coordinator', value: 'Resort Coordinator' },
  { label: 'Resort Team Leader', value: 'Resort Team Leader' },
  { label: 'Service Support Team Leader', value: 'Service Support Team Leader' },
  { label: 'Team Leader', value: 'Team Leader' },
  { label: 'Team Leader (L&M)', value: 'Team Leader (L&M)' },
  { label: 'Airport Team Manager', value: 'Airport Team Manager' },
  { label: 'Service Support Team Manager', value: 'Service Support Team Manager' },
  { label: 'Team Manager', value: 'Team Manager' },
  { label: 'Team Manager (L&M)', value: 'Team Manager (L&M)' },
];

export const WinterChangeRoleJobTitle = [
  { label: 'Baby Club Host', value: 'Baby Club Host' },
  { label: 'Kids Club Host', value: 'Kids Club Host' },
  { label: 'Entertainment & Activities Host', value: 'Entertainment & Activities Host' },
  { label: 'Fitness & Leisure Jumper', value: 'Fitness & Leisure Jumper' },
  { label: 'Fitness Instructor', value: 'Fitness Instructor' },
  { label: 'Football Coach', value: 'Football Coach' },
  { label: 'Swim Coach', value: 'Swim Coach' },
  { label: 'SENSATORI Expert', value: 'SENSATORI Expert' },
  { label: 'Kids Club Supervisor', value: 'Kids Club Supervisor' },
  { label: 'BLUE Guide Team Leader', value: 'BLUE Guide Team Leader' },
  { label: 'Childcare Delivery Team Leader', value: 'Childcare Delivery Team Leader' },
  { label: 'Entertainment & Activities Team Leader', value: 'Entertainment & Activities Team Leader' },
  { label: 'Holiday Village Academy Team Leader', value: 'Holiday Village Academy Team Leader' },
  { label: 'BLUE Guide Manager', value: 'BLUE Guide Manager' },
  { label: 'Childcare Delivery Team Manager', value: 'Childcare Delivery Team Manager' },
  { label: 'Entertainment Delivery Team Manager', value: 'Entertainment Delivery Team Manager' },
  { label: 'Holiday Village Delivery Manager', value: 'Holiday Village Delivery Manager' },
  { label: 'Airport Rep', value: 'Airport Rep' },
  { label: 'Excursion Rep', value: 'Excursion Rep' },
  { label: 'Quality Assurance Coordinator', value: 'Quality Assurance Coordinator' },
  { label: 'The Mora Experience Expert', value: 'The Mora Experience Expert' },
  { label: 'BLUE Guide', value: 'BLUE Guide' },
  { label: 'Experiences Expert', value: 'Experiences Expert' },
  { label: 'Hotel Rep', value: 'Hotel Rep' },
  { label: 'Hotel Rep (L&M)', value: 'Hotel Rep (L&M)' },
  { label: 'Service Support Rep', value: 'Service Support Rep' },
  { label: 'Service Support Rep (L&M)', value: 'Service Support Rep (L&M)' },
  { label: 'Blue Star Service & Activities Host', value: 'Blue Star Service & Activities Host' },
  { label: 'TIME TO SMILE Service Host', value: 'TIME TO SMILE Service Host' },
  { label: 'Airport Team Leader', value: 'Airport Team Leader' },
  { label: 'Resort Coordinator', value: 'Resort Coordinator' },
  { label: 'Resort Team Leader', value: 'Resort Team Leader' },
  { label: 'Service Support Team Leader', value: 'Service Support Team Leader' },
  { label: 'Team Leader', value: 'Team Leader' },
  { label: 'Team Leader (L&M)', value: 'Team Leader (L&M)' },
  { label: 'Airport Team Manager', value: 'Airport Team Manager' },
  { label: 'Service Support Team Manager', value: 'Service Support Team Manager' },
  { label: 'Team Manager', value: 'Team Manager' },
  { label: 'Team Manager (L&M)', value: 'Team Manager (L&M)' },
];

export const SummerChangeRoleJobTitle = [
  { label: 'Baby Club Host', value: 'Baby Club Host' },
  { label: 'Kids Club Host', value: 'Kids Club Host' },
  { label: 'Entertainment & Activities Host', value: 'Entertainment & Activities Host' },
  { label: 'Fitness & Leisure Jumper', value: 'Fitness & Leisure Jumper' },
  { label: 'Fitness Instructor', value: 'Fitness Instructor' },
  { label: 'Football Coach', value: 'Football Coach' },
  { label: 'Swim Coach', value: 'Swim Coach' },
  { label: 'SENSATORI Expert', value: 'SENSATORI Expert' },
  { label: 'Kids Club Supervisor', value: 'Kids Club Supervisor' },
  { label: 'BLUE Guide Team Leader', value: 'BLUE Guide Team Leader' },
  { label: 'Childcare Delivery Team Leader', value: 'Childcare Delivery Team Leader' },
  { label: 'Entertainment & Activities Team Leader', value: 'Entertainment & Activities Team Leader' },
  { label: 'Holiday Village Academy Team Leader', value: 'Holiday Village Academy Team Leader' },
  { label: 'BLUE Guide Manager', value: 'BLUE Guide Manager' },
  { label: 'Childcare Delivery Team Manager', value: 'Childcare Delivery Team Manager' },
  { label: 'Entertainment Delivery Team Manager', value: 'Entertainment Delivery Team Manager' },
  { label: 'Holiday Village Delivery Manager', value: 'Holiday Village Delivery Manager' },
  { label: 'Airport Rep', value: 'Airport Rep' },
  { label: 'Excursion Rep', value: 'Excursion Rep' },
  { label: 'Quality Assurance Coordinator', value: 'Quality Assurance Coordinator' },
  { label: 'The Mora Experience Expert', value: 'The Mora Experience Expert' },
  { label: 'BLUE Guide', value: 'BLUE Guide' },
  { label: 'Experiences Expert', value: 'Experiences Expert' },
  { label: 'Hotel Rep', value: 'Hotel Rep' },
  { label: 'Hotel Rep (L&M)', value: 'Hotel Rep (L&M)' },
  { label: 'Service Support Rep', value: 'Service Support Rep' },
  { label: 'Service Support Rep (L&M)', value: 'Service Support Rep (L&M)' },
  { label: 'Blue Star Service & Activities Host', value: 'Blue Star Service & Activities Host' },
  { label: 'TIME TO SMILE Service Host', value: 'TIME TO SMILE Service Host' },
  { label: 'Airport Team Leader', value: 'Airport Team Leader' },
  { label: 'Resort Coordinator', value: 'Resort Coordinator' },
  { label: 'Resort Team Leader', value: 'Resort Team Leader' },
  { label: 'Service Support Team Leader', value: 'Service Support Team Leader' },
  { label: 'Team Leader', value: 'Team Leader' },
  { label: 'Team Leader (L&M)', value: 'Team Leader (L&M)' },
  { label: 'Airport Team Manager', value: 'Airport Team Manager' },
  { label: 'Service Support Team Manager', value: 'Service Support Team Manager' },
  { label: 'Team Manager', value: 'Team Manager' },
  { label: 'Team Manager (L&M)', value: 'Team Manager (L&M)' },
];

export const WinterDestinations = [
  { label: 'Agadir', value: 'Agadir' },
  { label: 'Algarve', value: 'Algarve' },
  { label: 'Antalya', value: 'Antalya' },
  { label: 'Boa Vista', value: 'Boa Vista' },
  { label: 'Costa del Sol', value: 'Costa del Sol' },
  { label: 'Cyprus East', value: 'Cyprus East' },
  { label: 'Cyprus West', value: 'Cyprus West' },
  { label: 'Fuerteventura', value: 'Fuerteventura' },
  { label: 'Gran Canaria', value: 'Gran Canaria' },
  { label: 'Hurghada', value: 'Hurghada' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'Lanzarote', value: 'Lanzarote' },
  { label: 'Lapland', value: 'Lapland' },
  { label: 'Lapland - Ivalo', value: 'Lapland - Ivalo' },
  { label: 'Lapland - Kuusamo', value: 'Lapland - Kuusamo' },
  { label: 'Lapland - Rovaniemi', value: 'Lapland - Rovaniemi' },
  { label: 'Madeira', value: 'Madeira' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marrakesh', value: 'Marrakesh' },
  { label: 'Marsa Alam', value: 'Marsa Alam' },
  { label: 'Sal', value: 'Sal' },
  { label: 'Sharm El Sheik', value: 'Sharm El Sheik' },
  { label: 'Tenerife', value: 'Tenerife' },
  { label: 'Tunisia - Mainland', value: 'Tunisia - Mainland' },
];

export const SummerDestinations = [
  { label: 'Agadir', value: 'Agadir' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algarve', value: 'Algarve' },
  { label: 'Antalya', value: 'Antalya' },
  { label: 'Araxos', value: 'Araxos' },
  { label: 'Azores', value: 'Azores' },
  { label: 'Boa Vista', value: 'Boa Vista' },
  { label: 'Bodrum', value: 'Bodrum' },
  { label: 'Bourgas', value: 'Bourgas' },
  { label: 'Calabria', value: 'Calabria' },
  { label: 'Corfu', value: 'Corfu' },
  { label: 'Costa Almeria', value: 'Costa Almeria' },
  { label: 'Costa Blanca', value: 'Costa Blanca' },
  { label: 'Costa Brava', value: 'Costa Brava' },
  { label: 'Costa de la Luz', value: 'Costa de la Luz' },
  { label: 'Costa del Sol', value: 'Costa del Sol' },
  { label: 'Costa Dorada', value: 'Costa Dorada' },
  { label: 'Crete - Chania', value: 'Crete - Chania' },
  { label: 'Crete - Heraklion', value: 'Crete - Heraklion' },
  { label: 'Cyprus East', value: 'Cyprus East' },
  { label: 'Cyprus West', value: 'Cyprus West' },
  { label: 'Dalaman', value: 'Dalaman' },
  { label: 'Dubrovnik', value: 'Dubrovnik' },
  { label: 'Fuerteventura', value: 'Fuerteventura' },
  { label: 'Gran Canaria', value: 'Gran Canaria' },
  { label: 'Halkidiki', value: 'Halkidiki' },
  { label: 'Huelva', value: 'Huelva' },
  { label: 'Hurghada', value: 'Hurghada' },
  { label: 'Ibiza', value: 'Ibiza' },
  { label: 'Italy - Como', value: 'Italy - Como' },
  { label: 'Italy - Garda', value: 'Italy - Garda' },
  { label: 'Italy - Maggiore', value: 'Italy - Maggiore' },
  { label: 'Izmir', value: 'Izmir' },
  { label: 'Kalamata', value: 'Kalamata' },
  { label: 'Karpathos', value: 'Karpathos' },
  { label: 'Kefalonia', value: 'Kefalonia' },
  { label: 'Kos', value: 'Kos' },
  { label: 'Lanzarote', value: 'Lanzarote' },
  { label: 'Lesbos', value: 'Lesbos' },
  { label: 'Macedonia', value: 'Macedonia' },
  { label: 'Madeira', value: 'Madeira' },
  { label: 'Mallorca', value: 'Mallorca' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marrakesh', value: 'Marrakesh' },
  { label: 'Marsa Alam', value: 'Marsa Alam' },
  { label: 'Menorca', value: 'Menorca' },
  { label: 'Mykonos', value: 'Mykonos' },
  { label: 'Naples', value: 'Naples' },
  { label: 'Naxos', value: 'Naxos' },
  { label: 'Parga & Lefkada', value: 'Parga & Lefkada' },
  { label: 'Porto Santo', value: 'Porto Santo' },
  { label: 'Puglia', value: 'Puglia' },
  { label: 'Pula', value: 'Pula' },
  { label: 'Rhodes', value: 'Rhodes' },
  { label: 'Rijeka', value: 'Rijeka' },
  { label: 'Sal', value: 'Sal' },
  { label: 'Salzburg', value: 'Salzburg' },
  { label: 'Samos', value: 'Samos' },
  { label: 'Santorini', value: 'Santorini' },
  { label: 'Sardinia - Alghero', value: 'Sardinia - Alghero' },
  { label: 'Sardinia - Olbia', value: 'Sardinia - Olbia' },
  { label: 'Sharm El Sheik', value: 'Sharm El Sheik' },
  { label: 'Sicily - Catania', value: 'Sicily - Catania' },
  { label: 'Sicily - Palermo', value: 'Sicily - Palermo' },
  { label: 'Skiathos', value: 'Skiathos' },
  { label: 'Split', value: 'Split' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Tenerife', value: 'Tenerife' },
  { label: 'Thassos', value: 'Thassos' },
  { label: 'Tivat', value: 'Tivat' },
  { label: 'Tunisia - Djerba', value: 'Tunisia - Djerba' },
  { label: 'Tunisia - Mainland', value: 'Tunisia - Mainland' },
  { label: 'Varna', value: 'Varna' },
  { label: 'Zakynthos', value: 'Zakynthos' },
];

// create a method that maps the JobFamilies to JobTitles
// with switch case
export const JobFamilyToJobTitlesMapping = (jobFamily) => {
  switch (jobFamily) {
    case 'Concept - Childcare':
      return [
        { label: 'Baby Club Host', value: 'Baby Club Host' },
        { label: 'Kids Club Host', value: 'Kids Club Host' },
      ];
    case 'Concept - Entertainment & Leisure':
      return [
        { label: 'Entertainment & Activities Host', value: 'Entertainment & Activities Host' },
        { label: 'Fitness & Leisure Jumper', value: 'Fitness & Leisure Jumper' },
        { label: 'Fitness Instructor', value: 'Fitness Instructor' },
        { label: 'Football Coach', value: 'Football Coach' },
        { label: 'Swim Coach', value: 'Swim Coach' },
      ];
    case 'Concept - Reps':
      return [{ label: 'SENSATORI Expert', value: 'SENSATORI Expert' }];
    case 'Concept - Supervisors':
      return [{ label: 'Kids Club Supervisor', value: 'Kids Club Supervisor' }];
    case 'Concept - Team Leaders':
      return [
        { label: 'BLUE Guide Team Leader', value: 'BLUE Guide Team Leader' },
        { label: 'Childcare Delivery Team Leader', value: 'Childcare Delivery Team Leader' },
        { label: 'Entertainment & Activities Team Leader', value: 'Entertainment & Activities Team Leader' },
        { label: 'Holiday Village Academy Team Leader', value: 'Holiday Village Academy Team Leader' },
      ];
    case 'Concept - Team Managers':
      return [
        { label: 'BLUE Guide Manager', value: 'BLUE Guide Manager' },
        { label: 'Childcare Delivery Team Manager', value: 'Childcare Delivery Team Manager' },
        { label: 'Entertainment Delivery Team Manager', value: 'Entertainment Delivery Team Manager' },
        { label: 'Holiday Village Delivery Manager', value: 'Holiday Village Delivery Manager' },
      ];
    case 'Core - Airport':
      return [{ label: 'Airport Rep', value: 'Airport Rep' }];
    case 'Core - Other':
      return [
        { label: 'Excursion Rep', value: 'Excursion Rep' },
        { label: 'Quality Assurance Coordinator', value: 'Quality Assurance Coordinator' },
        { label: 'The Mora Experience Expert', value: 'The Mora Experience Expert' },
      ];
    case 'Core - Reps':
      return [
        { label: 'BLUE Guide', value: 'BLUE Guide' },
        { label: 'Experiences Expert', value: 'Experiences Expert' },
        { label: 'Hotel Rep', value: 'Hotel Rep' },
        { label: 'Hotel Rep (L&M)', value: 'Hotel Rep (L&M)' },
        { label: 'Service Support Rep', value: 'Service Support Rep' },
        { label: 'Service Support Rep (L&M)', value: 'Service Support Rep (L&M)' },
      ];
    case 'Core - SM Paid':
      return [
        { label: 'Blue Star Service & Activities Host', value: 'Blue Star Service & Activities Host' },
        { label: 'TIME TO SMILE Service Host', value: 'TIME TO SMILE Service Host' },
      ];
    case 'Core - Team Leaders':
      return [
        { label: 'Airport Team Leader', value: 'Airport Team Leader' },
        { label: 'Resort Coordinator', value: 'Resort Coordinator' },
        { label: 'Resort Team Leader', value: 'Resort Team Leader' },
        { label: 'Service Support Team Leader', value: 'Service Support Team Leader' },
        { label: 'Team Leader', value: 'Team Leader' },
        { label: 'Team Leader (L&M)', value: 'Team Leader (L&M)' },
      ];
    case 'Core - Team Managers':
      return [
        { label: 'Airport Team Manager', value: 'Airport Team Manager' },
        { label: 'Service Support Team Manager', value: 'Service Support Team Manager' },
        { label: 'Team Manager', value: 'Team Manager' },
        { label: 'Team Manager (L&M)', value: 'Team Manager (L&M)' },
      ];
    default:
      return [];
  }
};
