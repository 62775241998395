import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// react-datetime css
import 'react-datetime/css/react-datetime.css';
import { datadogRum } from '@datadog/browser-rum';

import TppSwitcher from 'Layout/Components/TppSwitcher';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';
import { authContext } from 'Configuration/AdalConfiguration';

import Layout from 'Layout/Layout';
import {
  getCountries,
  getDestinations,
  getJobFamilies,
  getJobTitles,
  getKeyWords,
  getLanguageLevel,
  getLanguages,
} from 'Redux/Actions/MasterData/MasterDataAction';
import { getTppSettings } from 'Redux/Actions/Setting/SettingAction';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobFamilies());
    dispatch(getJobTitles());
    dispatch(getCountries());
    dispatch(getDestinations());
    dispatch(getTppSettings());
    dispatch(getKeyWords());
    dispatch(getLanguages());
    dispatch(getLanguageLevel());
  }, []);

  datadogRum.init({
    applicationId: '574208f0-7c93-4e3a-8905-e48479bf96df',
    clientToken: 'pub277caed6629c99e14a2ee637c7bbb5f3',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'my-tui-profile',
    env: getEnvironmentVariablesHandler().environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: getEnvironmentVariablesHandler().version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  var { profile } = authContext.getCachedUser();
  datadogRum.setUser({
    email: profile.upn,
    name: profile.given_name,
  });

  return (
    <Layout>
      <TppSwitcher />
    </Layout>
  );
};

export default App;
