export const LongHaulGuestType = [
  { value: 'Families', label: 'Families' },
  { value: 'Adults', label: 'Adults' },
  { value: 'Couples', label: 'Couples' },
  { value: 'Big Team', label: 'Big Team' },
  { value: 'Small Team', label: 'Small Team' },
  { value: 'Independent', label: 'Independent' },
];

export const LongHaulInternationalConcepts = [
  { value: 'Suneo', label: 'Suneo' },
  { value: 'Sensatori', label: 'Sensatori' },
  { value: 'Scene', label: 'Scene' },
  { value: 'TUI Blue for Two', label: 'TUI Blue for Two' },
  { value: 'TUI Blue for Families', label: 'TUI Blue for Families' },
];

export const LongHaulNationalConcepts = [
  { value: 'Blue Star', label: 'Blue Star' },
  { value: 'Holiday Village', label: 'Holiday Village' },
  { value: 'L&M', label: 'L&M' },
  { value: 'Scene', label: 'Scene' },
  { value: 'Time to Smile', label: 'Time to Smile' },
];

export const LongHaulDestinations = [
  { value: 'Aruba', label: 'Aruba' },
  { value: 'Bali', label: 'Bali' },
  { value: 'Bangkok', label: 'Bangkok' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Bonaire', label: 'Bonaire' },
  { value: 'Cancun', label: 'Cancun' },
  { value: 'Curacao', label: 'Curacao' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Phuket', label: 'Phuket' },
  { value: 'Punta Cana', label: 'Punta Cana' },
  { value: 'St. Lucia', label: 'St. Lucia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Zanzibar', label: 'Zanzibar' },
  { value: 'Dubai', label: 'Dubai' },
  { value: 'Krabi', label: 'Krabi' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
];

export const LongHaulSourceMarkets = [
  {
    value: 'TUI Belgium',
    label: 'TUI Belgium',
  },
  { value: 'TUI Czech Republic', label: 'TUI Czech Republic' },
  { value: 'TUI Denmark', label: 'TUI Denmark' },
  { value: 'TUI Finland', label: 'TUI Finland' },
  { value: 'TUI France', label: 'TUI France' },
  { value: 'TUI GAS', label: 'TUI GAS' },
  { value: 'TUI Italy', label: 'TUI Italy' },
  { value: 'TUI Netherlands', label: 'TUI Netherlands' },
  { value: 'TUI Norway', label: 'TUI Norway' },
  { value: 'TUI Poland', label: 'TUI Poland' },
  { value: 'TUI Spain', label: 'TUI Spain' },
  { value: 'TUI Sweden', label: 'TUI Sweden' },
  { value: 'TUI UK&I', label: 'TUI UK&I' },
];

export const LongHaulJobFamilies = [
  { value: 'Core - Reps', label: 'Core - Reps' },
  { value: 'Concept - Team Leaders', label: 'Concept - Team Leaders' },
  { value: 'Concept - Childcare', label: 'Concept - Childcare' },
  { value: 'Concept - Entertainment & Leisure', label: 'Concept - Entertainment & Leisure' },
  { value: 'Core - Team Leaders', label: 'Core - Team Leaders' },
  { value: 'Concept - Supervisors', label: 'Concept - Supervisors' },
  { value: 'Concept - Team Managers', label: 'Concept - Team Managers' },
  { value: 'Core - Team Managers', label: 'Core - Team Managers' },
  { value: 'Core - SM Paid', label: 'Core - SM Paid' },
];

export const LongHaulJobTitles = [
  { value: 'Service Support Rep', label: 'Service Support Rep' },
  { value: 'Childcare Delivery Team Leader', label: 'Childcare Delivery Team Leader' },
  { value: 'Excursion Rep', label: 'Excursion Rep' },
  { value: 'Kids Club Host', label: 'Kids Club Host' },
  { value: 'Hotel Rep', label: 'Hotel Rep' },
  { value: 'BLUE Guide', label: 'BLUE Guide' },
  { value: 'Experience & Activity Host', label: 'Experience & Activity Host' },
  { value: 'Baby Club Host', label: 'Baby Club Host' },
  { value: 'Fitness & Leisure Instructor', label: 'Fitness & Leisure Instructor' },
  { value: 'Acting Hotel & Resort Team Leader', label: 'Acting Hotel & Resort Team Leader' },
  { value: 'Football Coach', label: 'Football Coach' },
  { value: 'BLUE Guide Team Leader (FOR TWO)', label: 'BLUE Guide Team Leader (FOR TWO)' },
  { value: 'Resort Team Leader', label: 'Resort Team Leader' },
  { value: 'Experiences Expert', label: 'Experiences Expert' },
  { value: 'Entertainment & Activity Host', label: 'Entertainment & Activity Host' },
  { value: 'Hotel Rep (L&M)', label: 'Hotel Rep (L&M)' },
  { value: 'Kids Club Supervisor', label: 'Kids Club Supervisor' },
  { value: 'BLUE Guide Team Leader (FOR ALL)', label: 'BLUE Guide Team Leader (FOR ALL)' },
  { value: 'Entertainment Delivery Team Leader', label: 'Entertainment Delivery Team Leader' },
  { value: 'Swim Coach', label: 'Swim Coach' },
  { value: 'Holiday Village Delivery Manager', label: 'Holiday Village Delivery Manager' },
  { value: 'Team Manager', label: 'Team Manager' },
  { value: 'Childcare Delivery Team Manager', label: 'Childcare Delivery Team Manager' },
  { value: 'Holiday Village Academy Team Leader', label: 'Holiday Village Academy Team Leader' },
  { value: 'Hotel Team Leader', label: 'Hotel Team Leader' },
  { value: 'Daytime Entertainer', label: 'Daytime Entertainer' },
  { value: 'BLUE Star Service & Activity Host', label: 'BLUE Star Service & Activity Host' },
  { value: 'BLUE Guide Manager', label: 'BLUE Guide Manager' },
  { value: 'Hotel & Resort Team Leader', label: 'Hotel & Resort Team Leader' },
  { value: 'Crystal Ski Team Leader', label: 'Crystal Ski Team Leader' },
  { value: 'Entertainment Delivery Team Manager', label: 'Entertainment Delivery Team Manager' },
  { value: 'Hotel Area Rep', label: 'Hotel Area Rep' },
  { value: 'Evening Entertainer', label: 'Evening Entertainer' },
  { value: 'Airport Team Leader', label: 'Airport Team Leader' },
  { value: 'TIME TO SMILE Service Host', label: 'TIME TO SMILE Service Host' },
  { value: 'Resort Team Leader (L&M)', label: 'Resort Team Leader (L&M)' },
  { value: 'Quality Assurance Coordinator', label: 'Quality Assurance Coordinator' },
  { value: 'Crystal Ski Childcare Rep', label: 'Crystal Ski Childcare Rep' },
  { value: 'Team Manager (L&M)', label: 'Team Manager (L&M)' },
  { value: 'Airport Team Manager', label: 'Airport Team Manager' },
  { value: 'Resort Coordinator', label: 'Resort Coordinator' },
];
