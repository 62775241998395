import React from 'react';
import Clock from 'react-live-clock';
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const Footer = (props) => {
  return (
    <footer className="footer">
      <span>
        {props.envVariables.environment} | {props.envVariables.version}
      </span>
      <span style={{ marginLeft: '35%' }}>
        <Clock format={'DD MMMM YYYY | dddd, HH:mm:ss'} ticking={true} /> ({timeZone})
      </span>
      <span className="float-right">
        <a href="mailto:tpp-support@tui.com?Subject=Tpp Support" target="_top">
          Support
        </a>
        <span style={{ color: '#ddd' }}> | </span>
        <a href="https://confluence.tuigroup.com/display/ITDS/Tpp+3.0" target="_blank" rel="noreferrer">
          Wiki
        </a>
      </span>
    </footer>
  );
};

export default Footer;
