import { ActionTypes as types } from 'Constants/userConstants';

var defaultState = {
  given_name: '',
  family_name: '',
  upn: '',
  roles: [''],
};

export default function currentUserReducer(state = defaultState, action) {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        ...action.data.user,
      };
    default:
      return state;
  }
}
