import { ActionTypes as types } from 'Constants/masterDataConstants';

// Initial state
const initialState = {
  sourceMarkets: [],
  destinations: [],
  seasons: [],
  jobTitles: [],
  jobFamilies: [],
  positionTypes: [],
  countries: [],
  regions: [],
  keywords: [],
  languages: [],
  languageLevels: [],
};

// Reducer function
const MasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SOURCEMARKETS_SUCCESS:
      return {
        ...state,
        sourceMarkets: action.data.sourceMarkets,
      };
    case types.GET_JOBFAMILIES_SUCCESS:
      return {
        ...state,
        jobFamilies: action.data.jobFamilies,
      };
    case types.GET_POSITION_TYPES_SUCCESS:
      return {
        ...state,
        positionTypes: action.data.positionTypes,
      };
    case types.GET_DESTINATIONS_SUCCESS:
      return {
        ...state,
        destinations: action.data.destinations,
      };
    case types.GET_JOBTITLES_SUCCESS:
      return {
        ...state,
        jobTitles: action.data.jobTitles,
      };
    case types.GET_SEASONS_SUCCESS:
      return {
        ...state,
        seasons: action.data.seasons,
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data.countries,
      };
    case types.GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.data.regions,
      };
    case types.GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.data.keywords,
      };
    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.data.languages,
      };

    case types.GET_LANGUAGE_LEVEL_SUCCESS:
      return {
        ...state,
        languageLevels: action.data.languageLevels,
      };
    default:
      return state;
  }
};

export default MasterDataReducer;
