import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';

import JobAssigmentView from 'Views/Assigments/JobAssigmentView';
import MtpStaffProfile from 'Views/Staff/MtpStaffProfileView';
import RepWorkApplication from 'Views/WorkApplication/RepWorkApplicationView';
import ManageRepView from 'Views/ManageRep/ManageRepView';
import NotFound from 'Views/NotFound';

import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';

const TppSwitcher = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const settings = useSelector((state) => state.settings);
  // console.log(settings);

  return (
    <BrowserRouter>
      <Switch>
        {/* Redirects */}
        <Route path="/" exact component={() => <Redirect to="/mtp/profile" />} />

        <Route path="/mtp/profile" component={() => <MtpStaffProfile />} />
        {(settings.applyOpen === 'Yes' || settings.isDiamondPin) && (
          <Route path="/mtp/rep-work-application" component={() => <RepWorkApplication />} />
        )}

        {(settings.acceptDeclineOpen === 'Yes' || settings.isDiamondPin) && (
          <Route path="/mtp/assigments" component={() => <JobAssigmentView />} />
        )}

        {currentUser.isManager && settings.commentsOpen && (
          <Route path="/mtp/manage-rep" component={() => <ManageRepView />} />
        )}

        <Route path="*" component={() => <NotFound />} />
      </Switch>
    </BrowserRouter>
  );
};

export default TppSwitcher;
