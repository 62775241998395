import { ActionTypes as types } from 'Constants/userConstants';
import { authContext } from 'Configuration/AdalConfiguration';
import RestClient from 'Services/RestClientService';

export function getUser() {
  function getUserSuccess(user) {
    return {
      type: types.GET_USER_SUCCESS,
      data: { user },
    };
  }

  var staffResult = null;

  return async function (dispatch) {
    var { profile } = authContext.getCachedUser();
    const user = profile;

    const rolesToCheck = ['DS_MTP_TUI_DX_Senior_Managers', 'DS_MTP_TUI_DX_Team_Managers'];
    user.isManager = rolesToCheck.some((role) => user.roles.includes(role));

    // get variable from localStorage
    const isLocalStorageManager = localStorage.getItem('isManager') === 'true';
    if (isLocalStorageManager) user.isManager = true;

    if (!staffResult) staffResult = await RestClient.Get(`staff/getByEmail/${user.upn}`);
    user.staffId = staffResult ? staffResult.staffID : null;

    dispatch(getUserSuccess(user));
  };
}
