import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';

const ManagerLongHaulCommentsForm = (props) => {
  const { managerWorkApplication, setManagerWorkApplication, isManagerWorkApplicationSubmitted } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '0.5%',
  };
  return (
    <Card style={cardStyle}>
      <Card.Header>
        <i className="fa fa-comments-o mr-2" />
        Manager questionary
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Are you happy to support this application? {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value={true}
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerLongHaulForm: {
                          ...managerWorkApplication.managerLongHaulForm,
                          isManagerSupportApplication: e.target.value === 'true',
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                    }}
                    checked={managerWorkApplication.managerLongHaulForm?.isManagerSupportApplication == true}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value={false}
                    onChange={(e) => {
                      setManagerWorkApplication({
                        ...managerWorkApplication,
                        managerLongHaulForm: {
                          ...managerWorkApplication.managerLongHaulForm,
                          isManagerSupportApplication: e.target.value === 'true',
                        },
                        managerWorkApplicationForm: {
                          ...managerWorkApplication.managerWorkApplicationForm,
                        },
                      });
                    }}
                    checked={managerWorkApplication.managerLongHaulForm?.isManagerSupportApplication == false}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Comments {requiredField()}</Form.Label>
              <Form.Control
                as="textarea"
                maxLength={500}
                rows={8}
                onChange={(e) => {
                  setManagerWorkApplication({
                    ...managerWorkApplication,
                    managerLongHaulForm: {
                      ...managerWorkApplication.managerLongHaulForm,
                      comments: e.target.value,
                    },
                  });
                }}
                value={managerWorkApplication.managerLongHaulForm?.comments}
                disabled={isManagerWorkApplicationSubmitted}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ManagerLongHaulCommentsForm;
