import keyMirror from 'keymirror';

export var ActionTypes = keyMirror({
  GET_USER_SUCCESS: null,
});

export var UserRoles = keyMirror({
  DS_F2W_Business_Travel_Team: null,
  DS_F2W_Edit: null,
  DS_F2W_HR_Team: null,
  DS_GPX_Edit: null,
  DS_GPX_Read_Only: null,
  DS_MTP_Edit: null,
  DS_MTP_TUI_DX_Senior_Managers: null,
  DS_MTP_TUI_DX_Team_Managers: null,
});
