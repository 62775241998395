import React from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';

import {
  LongHaulGuestType,
  LongHaulInternationalConcepts,
  LongHaulJobFamilies,
  LongHaulSourceMarkets,
  LongHaulNationalConcepts,
  LongHaulDestinations,
  LongHaulJobTitles,
} from 'Handlers/LongHaulDropDownsHandler';
import { DATE_FORMAT_ISO, DATE_FORMAT_WEB } from 'Constants/dateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const RepLongHaulForm = (props) => {
  const { repWorkApplication, repWorkApplicationError, setRepWorkApplication, isWorkApplicationSubmitted } = props;

  const yesNoOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const cardStyle = {
    marginTop: '1%',
  };
  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  return (
    <>
      <h2 style={cardStyle}>Long Haul</h2>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-user mr-2" />
          Fill the required information
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Are you comfortable to serve any other Source Market? {requiredField()}</Form.Label>
                <Select
                  options={LongHaulSourceMarkets}
                  value={
                    repWorkApplication.repLongHaulForm?.confortableSourceMarket?.map((value) =>
                      LongHaulSourceMarkets.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          confortableSourceMarket: [],
                        },
                      });
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        confortableSourceMarket: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.confortableSourceMarket && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.confortableSourceMarket}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Passport Expiry </Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(repWorkApplication.repLongHaulForm?.passportExpiry, null, DATE_FORMAT_WEB)}
                  onChange={(e) => {
                    if (!e) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          passportExpiry: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        passportExpiry: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                  }}
                />
                {repWorkApplicationError.repLongHaulForm.passportExpiry && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.passportExpiry}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Current Job Family {requiredField()}</Form.Label>
                <Select
                  options={LongHaulJobFamilies}
                  value={
                    LongHaulJobFamilies.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.currentJobFamily
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          currentJobFamily: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        currentJobFamily: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.currentJobFamily && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.currentJobFamily}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Current Job Title {requiredField()}</Form.Label>
                <Select
                  options={LongHaulJobTitles}
                  value={
                    LongHaulJobTitles.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.currentJobTitle
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          currentJobTitle: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        currentJobTitle: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.currentJobTitle && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.currentJobTitle}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>What Guest Type are you happy to work with? {requiredField()}</Form.Label>
                <Select
                  options={LongHaulGuestType}
                  value={
                    repWorkApplication.repLongHaulForm?.guestType?.map((value) =>
                      LongHaulGuestType.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          guestType: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        guestType: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>International Concepts {requiredField()}</Form.Label>
                <Select
                  options={LongHaulInternationalConcepts}
                  value={
                    repWorkApplication.repLongHaulForm?.internationalConcept?.map((value) =>
                      LongHaulInternationalConcepts.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          internationalConcept: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        internationalConcept: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>National Concepts {requiredField()}</Form.Label>
                <Select
                  options={LongHaulNationalConcepts}
                  value={
                    repWorkApplication.repLongHaulForm?.nationalConcept?.map((value) =>
                      LongHaulNationalConcepts.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          nationalConcept: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        nationalConcept: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Are you willing to work in a 1 year placement? {requiredField()}</Form.Label>
                <Select
                  options={yesNoOptions}
                  value={
                    repWorkApplication.repLongHaulForm?.isWillingWorkOneYear
                      ? { value: 'yes', label: 'Yes' }
                      : { value: 'no', label: 'No' }
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          isWillingWorkOneYear: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        isWillingWorkOneYear: e.value.toLowerCase() === 'yes',
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Have you worked Long Haul before? {requiredField()}</Form.Label>
                <Select
                  options={yesNoOptions}
                  value={
                    repWorkApplication.repLongHaulForm?.isWorkedInLongHaul
                      ? { value: 'yes', label: 'Yes' }
                      : { value: 'no', label: 'No' }
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          isWorkedInLongHaul: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        isWorkedInLongHaul: e.value.toLowerCase() === 'yes',
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>If yes, where? {requiredField()}</Form.Label>
                <Form.Control
                  as="input"
                  onChange={(e) => {
                    if (!e) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          isWorkedInLongHaulYes: '',
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        isWorkedInLongHaulYes: e.target.value,
                      },
                    });
                  }}
                  disabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Destination One {requiredField()}</Form.Label>
                <Select
                  options={LongHaulDestinations}
                  value={
                    LongHaulDestinations.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.destinationOne
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          destinationOne: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        destinationOne: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.destinationOne && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.destinationOne}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination Two {requiredField()}</Form.Label>
                <Select
                  options={LongHaulDestinations}
                  value={
                    LongHaulDestinations.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.destinationTwo
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          destinationTwo: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        destinationTwo: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.destinationTwo && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.destinationTwo}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination Three {requiredField()}</Form.Label>
                <Select
                  options={LongHaulDestinations}
                  value={
                    LongHaulDestinations.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.destinationThree
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          destinationThree: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        destinationThree: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.destinationThree && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.destinationThree}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination Four {requiredField()}</Form.Label>
                <Select
                  options={LongHaulDestinations}
                  value={
                    LongHaulDestinations.find(
                      (option) => option.value === repWorkApplication.repLongHaulForm?.destinationFour
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repLongHaulForm: {
                          ...repWorkApplication.repLongHaulForm,
                          destinationFour: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        destinationFour: e.value,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repLongHaulForm.destinationFour && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repLongHaulForm.destinationFour}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Why do you think you are suitable for a Long Haul Placement? {requiredField()}</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={500}
                  rows={8}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repLongHaulForm: {
                        ...repWorkApplication.repLongHaulForm,
                        whyYouSuitable: e.target.value,
                      },
                    });
                  }}
                  value={repWorkApplication.repLongHaulForm?.whyYouSuitable}
                  disabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              {repWorkApplicationError.repLongHaulForm.whyYouSuitable && (
                <Form.Text className="form-text-red">
                  {repWorkApplicationError.repLongHaulForm.whyYouSuitable}
                </Form.Text>
              )}
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default RepLongHaulForm;
