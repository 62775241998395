import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Button, Row, Badge, Modal, Accordion } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB, DATE_FORMAT_WEB } from 'Constants/dateFormatConstants';
import RestClient from 'Services/RestClientService';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { useDispatch } from 'react-redux';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const JobAssignmentCard = (props) => {
  // console.log(props.assignemnt);
  const dispatch = useDispatch();
  const { assignemnt, employee, setRefreshParentComponent, refreshParentComponent } = props;

  const keyWorkdsOptionsList = [
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Decline', value: 'Decline' },
  ];
  const [showEditAssigmentModal, setShowEditAssignemntModal] = useState(false);
  const [desiredAssigment, setDesiredAssigment] = useState({
    positionAssignId: assignemnt.positionAssignId,
    mtpAssigmentStatus: assignemnt.mtpAssigmentStatus,
    isAssignmentChanged: false,
    mtpAssigmentComment: '',
  });
  const [desiredAssigmentError, setDesiredAssigmentError] = useState({
    mtpAssigmentComment: '',
    mtpAssigmentStatus: '',
  });

  const [mtpHistoryList, setMtpHistoryList] = useState([
    {
      positionAssignId: 0,
      status: '',
      startDate: '',
      endDate: '',
      comment: '',
      timestamp: '',
      updtedBy: '',
    },
  ]);

  const validateEditAssignmentForm = () => {
    if (!desiredAssigment.mtpAssigmentStatus) {
      setDesiredAssigmentError({ mtpAssigmentStatus: 'Status is required' });
      return false;
    }

    if (!desiredAssigment.mtpAssigmentComment && desiredAssigment.mtpAssigmentStatus === 'Decline') {
      setDesiredAssigmentError({ mtpAssigmentComment: 'Please let us know why you are declining the assigment' });
      return false;
    }

    return true;
  };

  const handleEditAssigment = () => {
    const { mtpAssigmentStatus, mtpAssigmentComment } = desiredAssigment;
    const { staffID } = employee;
    const { mplid, positionAssignId } = assignemnt;

    if (!validateEditAssignmentForm()) {
      return;
    }
    setShowEditAssignemntModal(false);

    const payload = {
      mplId: mplid,
      positionAssignId: positionAssignId,
      staffId: staffID,
      mtpAssigmentStatus,
      mtpAssigmentComment,
      assigmentStatus: mtpAssigmentStatus,
      assigmentStartDate: assignemnt.startDate,
      assigmentEndDate: assignemnt.endDate,
      assigmentTypeOfFlight: 'Start of Season',
      assigmentStaffPositionType: assignemnt.assigmentStaffPositionType,
    };

    dispatch(setLoader(true));
    RestClient.Post(`positionAssign/update-assignment`, payload)
      .then((result) => {
        toastr.success('Success', result.message);
      })
      .finally(() => {
        setRefreshParentComponent(Math.random());
        setDesiredAssigmentError({});
        dispatch(setLoader(false));
      });
  };

  const setStatusColor = (status) => {
    switch (status) {
      case 'Accept':
      case '2nd Offer Accepted':
        return 'success';
      case 'Ready To Communicate Summer':
      case 'Ready To Communicate Winter':
        return 'warning';
      case 'Declined':
      case 'Decline':
        return 'danger';
      default:
        return 'primary';
    }
  };

  useEffect(() => {
    // console.log(assignemnt);
  }, [refreshParentComponent]);

  useEffect(() => {
    RestClient.Get(`history/mtpHistoryByPositionAssignId/${assignemnt.positionAssignId}`).then((serverResponse) => {
      // console.log('serverResponse', serverResponse);
      if (!serverResponse) return;
      setMtpHistoryList(serverResponse.mtpHistoryList);
    });
  }, [refreshParentComponent]);

  return (
    <>
      <Card className="staff-profile-card">
        <Card.Header className="d-flex justify-content-between">
          <div>
            <i className="fa fa-globe mr-2"></i>
            <i className="fa fa-location-arrow mr-2"></i>
            Position propoused for : {employee.firstName} {employee.lastName}
          </div>
          <div>
            <Badge variant={setStatusColor(assignemnt.mtpAssigmentStatus)} pill style={{ fontSize: '0.8rem' }}>
              Mtp status : {assignemnt.mtpAssigmentStatus}
            </Badge>
          </div>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Position assign id</Form.Label>
                <Form.Control type="text" value={assignemnt.positionAssignId} disabled />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Mplid</Form.Label>
                <Form.Control type="text" value={assignemnt.mplid} disabled />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Season</Form.Label>
                <Form.Control type="text" value={assignemnt.season} disabled />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Concept Hotel</Form.Label>
                <Form.Control type="text" value={assignemnt.conceptHotel} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Region</Form.Label>
                <Form.Control type="text" value={`${assignemnt.region}`} disabled />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Destination</Form.Label>
                <Form.Control type="text" value={assignemnt.destination} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Job family</Form.Label>
                <Form.Control type="text" value={assignemnt.jobFamily} disabled />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Job title </Form.Label>
                <Form.Control type="text" value={assignemnt.jobTitle} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Assignment start date</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                  value={DateTimeFormater(assignemnt.startDate, null, DATE_FORMAT_WEB)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Assignment end date</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                  value={DateTimeFormater(assignemnt.endDate, null, DATE_FORMAT_WEB)}
                />
              </Form.Group>
            </Form.Row>
          </Form>

          <hr></hr>

          <Accordion hidden={true}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Badge} pill variant="primary" eventKey="0">
                  See history
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Created by</th>
                        <th scope="col">Creation date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mtpHistoryList.map((history, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Badge variant={setStatusColor(history.status)} pill style={{ fontSize: '0.8rem' }}>
                                {history.status}
                              </Badge>
                            </td>
                            <td>{history.comment}</td>
                            <td>{history.updatedBy}</td>
                            <td>{DateTimeFormater(history.createdDate, null, DATETIME_FORMAT_WEB)}</td>
                          </tr>
                        );
                      })}
                      {mtpHistoryList.length === 0 && (
                        <tr>
                          <td colSpan="4" align="center">
                            No results found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: 'flex' }}>
            <Button
              onClick={() => setShowEditAssignemntModal(true)}
              variant="info"
              size="lg"
              style={{ margin: '0 5px' }}
            >
              Accept / Decline
            </Button>
          </Row>
        </Card.Footer>
      </Card>

      <Modal
        size="lg"
        show={showEditAssigmentModal}
        onHide={() => {
          setShowEditAssignemntModal(false);
        }}
        onExit={() => {
          setDesiredAssigment({
            id: assignemnt.positionAssignId,
            isAssignmentChanged: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>[{assignemnt.positionAssignId}] - Edit propoused assigment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Current Mtp Status : {assignemnt.mtpAssigmentStatus}</Form.Label>
                <Select
                  options={keyWorkdsOptionsList}
                  closeMenuOnSelect
                  isClearable
                  onChange={(e) => {
                    setDesiredAssigmentError({});
                    setDesiredAssigment({
                      ...desiredAssigment,
                      isAssignmentChanged: true,
                      mtpAssigmentStatus: e?.value,
                    });
                  }}
                  value={keyWorkdsOptionsList.filter((option) => {
                    return option.value === desiredAssigment.mtpAssigmentStatus;
                  })}
                />
                <Form.Text className="form-text-red">{desiredAssigmentError.mtpAssigmentStatus}</Form.Text>
              </Form.Group>
            </Form.Row>
            <div hidden={desiredAssigment.mtpAssigmentStatus != 'Decline'}>
              <hr></hr>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Other comments </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={assignemnt.comments}
                    onChange={(e) => {
                      setDesiredAssigmentError({});
                      setDesiredAssigment({
                        ...desiredAssigment,
                        isAssignmentChanged: true,
                        mtpAssigmentComment: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Text className="form-text-red">{desiredAssigmentError.mtpAssigmentComment}</Form.Text>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditAssignemntModal(false)}>
            Cancel
          </Button>
          <Button
            variant="info"
            onClick={() => handleEditAssigment(assignemnt.positionAssignId)}
            disabled={!desiredAssigment.isAssignmentChanged}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobAssignmentCard;
