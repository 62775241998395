import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { adalApiFetch } from 'Configuration/AdalConfiguration';
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

const baseUrl = `${getEnvironmentVariablesHandler().apiBaseUrl}`;
class RestClient {
  static async Get(url) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let response = await adalApiFetch(axiosInstance, baseUrl + url, {});
      // console.log(response);
      return response?.data;
    } catch (err) {
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message}.`);
    }
  }

  static async Post(url, data) {
    var axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    try {
      let settings = {
        headers: {
          Accept: 'Application/json',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: '',
        },
        data: JSON.stringify(data),
        method: 'POST',
      };

      let response = await adalApiFetch(axiosInstance, baseUrl + url, settings);

      return response?.data;
    } catch (err) {
      console.error(err);
      console.error(err.response?.data?.message);
      toastr.error('Error', `An unexpected error has occured: ${err.response?.data?.message}.`);
    }
  }
}

export default RestClient;
