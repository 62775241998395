import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { runWithAdal } from 'react-adal';

import App from 'App';
import Unauthorized from 'Views/Unauthorized';
import store from 'Configuration/StoreConfiguration';
import { authContext } from 'Configuration/AdalConfiguration';
import RestClient from 'Services/RestClientService';

const renderApp = (Component) => {
  ReactDOM.render(<Provider store={store}>{Component}</Provider>, document.getElementById('root'));
};

runWithAdal(authContext, async () => {
  try {
    // get adal profile user
    var { profile } = authContext.getCachedUser();

    console.log('profile', profile);

    // get roles from adal profile user and validate
    var roles = profile.roles;
    const validRoles = roles ? roles.filter((value) => value.includes('DS_MTP') || value.includes('DS_GPX')) : [];
    if (validRoles.length === 0) {
      throw new Error('401');
    }

    // get upn from adal profile user and validate
    var upn = profile.upn;
    var isUpnEmail = upn ? upn.includes('@') : upn;
    if (!isUpnEmail) {
      throw new Error('400');
    }

    // get staff id from tpp only if upn is email
    var staffResult = await RestClient.Get(`staff/getByEmail/${upn}`);
    var staffId = staffResult ? staffResult.staffID : null;
    if (!staffId) {
      throw new Error('404');
    }

    renderApp(<App />);
  } catch (error) {
    renderApp(<Unauthorized errorMessage={error.message} />);
  }
});
