import React, { useEffect } from 'react';
import { Card, Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';

const EmployeeWorkInfoCard = (props) => {
  const { employee, setEmployee } = props;

  const sourceMarketOptions = useSelector((state) => state.masterData.sourceMarkets);
  const positionTypesOptions = useSelector((state) => state.masterData.positionTypes);

  const driverLicenseOptions = [
    { label: 'Yes - Manual', value: 'Yes - Manual' },
    { label: 'Yes - Automatic', value: 'Yes - Automatic' },
    { label: 'No', value: 'No' },
  ];

  const mapDriverLicense = (driver) => {
    var result = '';
    switch (driver) {
      case 'No':
      case 'Local':
      case 'N/A':
      case '':
        result = 'No';
        break;
      case 'Driver 21+Auto':
      case 'Driver - 18+ Auto':
      case 'Driver - 21+ Auto':
      case 'Driver - 23+ Auto':
      case '25+ Auto':
      case 'Driver 18+ Auto':
        result = 'Yes - Automatic';
        break;
      case 'Driver - 25+':
      case 'Y':
      case 'YES':
      case 'Yes':
      case 'Driver - 23+':
      case 'Driver - 18+':
      case 'Driver - 21+':
      case 'Driver 25+':
      case 'Yes – 25+':
      case 'Yes – 21+':
      case 'Yes – 23+':
      case 'Driver +25':
      case 'Driver +18':
      case 'Yes +25':
      case 'Driver 21+':
      case 'Yes 23+':
      case 'Yes – 18+':
      case 'Driver':
      case 'Yes - 25+':
        result = 'Yes - Manual';
        break;
      default:
        result = driver;
        break;
    }

    return { label: result, value: result };
  };

  useEffect(() => {
    // console.log(employee.driver);
  }, [employee]);

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-info mr-2"></i>Employee Information
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Source Market</Form.Label>
              <Select
                value={{ label: employee.sourceMarket, value: employee.sourceMarket }}
                onChange={(e) => {
                  setEmployee({ ...employee, sourceMarket: e.value });
                }}
                options={sourceMarketOptions}
                isDisabled
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Position type</Form.Label>
              <Select
                value={{
                  label: employee.positionType,
                  value: employee.positionType,
                }}
                onChange={(e) => {
                  setEmployee({ ...employee, positionType: e.value });
                }}
                options={positionTypesOptions}
                isDisabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Driver License</Form.Label>
              <Select
                value={mapDriverLicense(employee.driver)}
                onChange={(e) => {
                  setEmployee({ ...employee, driver: e.value });
                }}
                options={driverLicenseOptions}
                isDisabled
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Driver Year</Form.Label>
              <Form.Control type="text" value={employee.drivingYear || ''} readOnly />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Education</Form.Label>
              <Form.Control as="textarea" value={employee.education} rows={3} readOnly />
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EmployeeWorkInfoCard;
