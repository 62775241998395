import keyMirror from 'keymirror';

export var ActionTypes = keyMirror({
  GET_SOURCEMARKETS_SUCCESS: null,
  GET_JOBFAMILIES_SUCCESS: null,
  GET_POSITION_TYPES_SUCCESS: null,
  GET_SEASONS_SUCCESS: null,
  GET_DESTINATIONS_SUCCESS: null,
  GET_JOBTITLES_SUCCESS: null,
  GET_COUNTRIES_SUCCESS: null,
  GET_REGIONS_SUCCESS: null,
  GET_KEYWORDS_SUCCESS: null,
  GET_LANGUAGE_LEVEL_SUCCESS: null,
  GET_LANGUAGES_SUCCESS: null,
});
