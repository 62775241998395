import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';

import {
  DATETIME_FORMAT_ISO,
  DATE_FORMAT_ISO,
  DATE_FORMAT_WEB,
  DATE_NO_DAY_FORMAT_WEB,
} from 'Constants/dateFormatConstants';
import { preferences_for_winter_options, customer_nationalities } from 'Constants/selectOptionsConstants';
import { WinterDestinations } from 'Handlers/BeachDropDownsHandler';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { WinterChangeRoleJobTitle } from 'Handlers/BeachDropDownsHandler';

const RepWinterForm = (props) => {
  const {
    repWorkApplication,
    setRepWorkApplication,

    repWorkApplicationError,
    setRepWorkApplicationError,

    isWorkApplicationSubmitted,
  } = props;

  const cardStyle = {
    marginTop: '1%',
  };
  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const checkForDuplicateDestinations = (newChoiceDestination) => {
    // Create an array with all destinations
    const destinations = [
      newChoiceDestination,
      repWorkApplication.repWinterForm.firstChoiceDestination,
      repWorkApplication.repWinterForm.secondChoiceDestination,
      repWorkApplication.repWinterForm.thirdChoiceDestination,
      repWorkApplication.repWinterForm.fourthChoiceDestination,
    ].filter((destination) => destination && destination.trim() !== '');

    // Use a Set to remove duplicates and compare its size to the original array
    const uniqueDestinations = new Set(destinations);

    if (uniqueDestinations.size < destinations.length) {
      // If the size of the Set is less than the original array, duplicates were removed
      toastr.warning('Warning: You have selected duplicate destinations.');
      return true; // Indicates duplicates were found
    }

    return false; // Indicates no duplicates were found
  };

  return (
    <>
      <h2 style={cardStyle}>Winter</h2>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-snowflake-o mr-2" />
          For next Winter (October-April) season I wish to work in the following destinations
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>My preference for winter work are {requiredField()}</Form.Label>
                <Select
                  options={preferences_for_winter_options}
                  value={
                    preferences_for_winter_options.find(
                      (option) => option.value === repWorkApplication.repWinterForm.preferencesFor
                    ) || null
                  }
                  onChange={(e) => {
                    // reset all fields
                    if (!e) {
                      setRepWorkApplication({
                        repGlobalForm: {
                          repComments: '',
                        },
                        repWinterForm: {},
                        repSummerForm: {},
                        repBeachForm: {},
                      });
                    } else {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {
                          ...repWorkApplicationError.repWinterForm,
                          preferencesFor: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repWinterForm: {
                          ...repWorkApplicationError.repWinterForm,
                          preferencesFor: null,
                        },
                      });
                    }
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repWinterForm.preferencesFor && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repWinterForm.preferencesFor}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                lg={3}
                hidden={
                  repWorkApplication.repWinterForm.preferencesFor !== 'Would like to work part winter only' ||
                  !repWorkApplication.repWinterForm.preferencesFor
                }
              >
                <Form.Label>My preference for winter start month {requiredField()}</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_NO_DAY_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_NO_DAY_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(
                    repWorkApplication.repWinterForm.preferenceStartMonth,
                    null,
                    DATE_NO_DAY_FORMAT_WEB
                  )}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repWinterForm: {
                        ...repWorkApplication.repWinterForm,
                        preferenceStartMonth: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repWinterForm: {
                        ...repWorkApplicationError.repWinterForm,
                        preferenceStartMonth: null,
                      },
                    });
                  }}
                  isValidDate={(currentDate) => {
                    const startOfPeriod = moment(new Date(2024, 9, 1)); // 1 de octubre de 2024
                    const endOfPeriod = moment(new Date(2025, 3, 30)); // 30 de abril de 2025
                    return currentDate.isSameOrAfter(startOfPeriod) && currentDate.isSameOrBefore(endOfPeriod);
                  }}
                />
                {repWorkApplicationError.repWinterForm.preferenceStartMonth && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repWinterForm.preferenceStartMonth}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group
                as={Col}
                lg={3}
                hidden={
                  repWorkApplication.repWinterForm.preferencesFor !== 'Would like to work part winter only' ||
                  !repWorkApplication.repWinterForm.preferencesFor
                }
              >
                <Form.Label>My preference for winter end month {requiredField()}</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_NO_DAY_FORMAT_WEB}
                  closeOnSelect
                  utc={true}
                  inputProps={{ placeholder: DATE_NO_DAY_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                  value={DateTimeFormater(
                    repWorkApplication.repWinterForm.preferenceEndMonth,
                    DATETIME_FORMAT_ISO,
                    DATE_NO_DAY_FORMAT_WEB
                  )}
                  onChange={(e) => {
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repWinterForm: {
                        ...repWorkApplication.repWinterForm,
                        preferenceEndMonth: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repWinterForm: {
                        ...repWorkApplicationError.repWinterForm,
                        preferenceEndMonth: null,
                      },
                    });
                  }}
                  isValidDate={(currentDate) => {
                    const startOfPeriod = moment(new Date(2024, 9, 1)); // 1 de octubre de 2024
                    const endOfPeriod = moment(new Date(2025, 3, 30)); // 30 de abril de 2025
                    return currentDate.isSameOrAfter(startOfPeriod) && currentDate.isSameOrBefore(endOfPeriod);
                  }}
                />
                {repWorkApplicationError.repWinterForm.preferenceEndMonth && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repWinterForm.preferenceStartMonth}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <div
              hidden={
                repWorkApplication.repWinterForm.preferencesFor ===
                  'I do not wish to work in winter but would like to return for summer' ||
                repWorkApplication.repWinterForm.preferencesFor ===
                  'I do not wish to work in winter and plan to resign at the end of summer'
              }
            >
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>First choice destination {requiredField()}</Form.Label>
                  <Select
                    options={WinterDestinations}
                    value={
                      WinterDestinations.find(
                        (option) => option.value === repWorkApplication.repWinterForm.firstChoiceDestination
                      ) || null
                    }
                    onChange={(e) => {
                      if (checkForDuplicateDestinations(e?.value)) {
                        return null;
                      } else {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repWinterForm: {
                            ...repWorkApplication.repWinterForm,
                            firstChoiceDestination: e?.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repWinterForm: {
                            ...repWorkApplicationError.repWinterForm,
                            firstChoiceDestination: null,
                          },
                        });
                      }
                    }}
                    isClearable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repWinterForm.firstChoiceDestination && (
                    <Form.Text className="form-text-red">
                      {repWorkApplicationError.repWinterForm.firstChoiceDestination}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Second choice destination {requiredField()}</Form.Label>
                  <Select
                    options={WinterDestinations}
                    value={
                      WinterDestinations.find(
                        (option) => option.value === repWorkApplication.repWinterForm.secondChoiceDestination
                      ) || null
                    }
                    onChange={(e) => {
                      if (checkForDuplicateDestinations(e?.value)) {
                        return null;
                      } else {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repWinterForm: {
                            ...repWorkApplication.repWinterForm,
                            secondChoiceDestination: e?.value,
                          },
                        });
                        setRepWorkApplicationError({
                          ...repWorkApplicationError,
                          repWinterForm: {
                            ...repWorkApplicationError.repWinterForm,
                            secondChoiceDestination: null,
                          },
                        });
                      }
                    }}
                    isClearable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repWinterForm.secondChoiceDestination && (
                    <Form.Text className="form-text-red">
                      {repWorkApplicationError.repWinterForm.secondChoiceDestination}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Third choice destination</Form.Label>
                  <Select
                    options={WinterDestinations}
                    value={
                      WinterDestinations.find(
                        (option) => option.value === repWorkApplication.repWinterForm.thirdChoiceDestination
                      ) || null
                    }
                    onChange={(e) => {
                      if (checkForDuplicateDestinations(e?.value)) {
                        return null;
                      } else {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repWinterForm: {
                            ...repWorkApplication.repWinterForm,
                            thirdChoiceDestination: e?.value,
                          },
                        });
                      }
                    }}
                    isClearable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Fourth choice destination</Form.Label>
                  <Select
                    options={WinterDestinations}
                    value={
                      WinterDestinations.find(
                        (option) => option.value === repWorkApplication.repWinterForm.fourthChoiceDestination
                      ) || null
                    }
                    onChange={(e) => {
                      if (checkForDuplicateDestinations(e?.value)) {
                        return null;
                      } else {
                        setRepWorkApplication({
                          ...repWorkApplication,
                          repWinterForm: {
                            ...repWorkApplication.repWinterForm,
                            fourthChoiceDestination: e?.value,
                          },
                        });
                      }
                    }}
                    isClearable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                </Form.Group>
              </Form.Row>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <div
        hidden={
          repWorkApplication.repWinterForm.preferencesFor ===
            'I do not wish to work in winter but would like to return for summer' ||
          repWorkApplication.repWinterForm.preferencesFor ===
            'I do not wish to work in winter and plan to resign at the end of summer'
        }
      >
        <Card style={cardStyle}>
          <Card.Header>
            <i className="fa fa-snowflake-o mr-2" />
            <i className="fa fa-user mr-2" />
            Personal preferences
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>
                    What customer nationalities are you willing and able to service with your language skills?{' '}
                    {requiredField()}
                  </Form.Label>
                  <Select
                    isMulti
                    options={customer_nationalities}
                    onChange={(selectedOptions) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {
                          ...repWorkApplication.repWinterForm,
                          customerNationalities: selectedOptions ? selectedOptions.map((option) => option.value) : [],
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repWinterForm: {
                          ...repWorkApplicationError.repWinterForm,
                          customerNationalities: null,
                        },
                      });
                    }}
                    value={(repWorkApplication.repWinterForm.customerNationalities || []).map((nat) =>
                      customer_nationalities.find((option) => option.value === nat)
                    )}
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repWinterForm.customerNationalities && (
                    <Form.Text className="form-text-red">
                      {repWorkApplicationError.repWinterForm.customerNationalities}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    Are you willing to complete a visa application process if required for your placement wish? (Winter){' '}
                    {requiredField()}
                  </Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        value="Yes"
                        checked={repWorkApplication.repWinterForm.willingCompleteVisaApplication === 'Yes'}
                        onChange={(e) => {
                          setRepWorkApplication({
                            ...repWorkApplication,
                            repWinterForm: {
                              ...repWorkApplication.repWinterForm,
                              willingCompleteVisaApplication: e.currentTarget.value,
                            },
                          });
                          setRepWorkApplicationError({
                            ...repWorkApplicationError,
                            repWinterForm: {
                              ...repWorkApplicationError.repWinterForm,
                              willingCompleteVisaApplication: null,
                            },
                          });
                        }}
                        disabled={isWorkApplicationSubmitted}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        value="No"
                        checked={repWorkApplication.repWinterForm.willingCompleteVisaApplication === 'No'}
                        onChange={(e) => {
                          setRepWorkApplication({
                            ...repWorkApplication,
                            repWinterForm: {
                              ...repWorkApplication.repWinterForm,
                              willingCompleteVisaApplication: e.currentTarget.value,
                            },
                          });
                          setRepWorkApplicationError({
                            ...repWorkApplicationError,
                            repWinterForm: {
                              ...repWorkApplicationError.repWinterForm,
                              willingCompleteVisaApplication: null,
                            },
                          });
                        }}
                        disabled={isWorkApplicationSubmitted}
                      />
                      {repWorkApplicationError.repWinterForm.willingCompleteVisaApplication && (
                        <Form.Text className="form-text-red">
                          {repWorkApplicationError.repWinterForm.willingCompleteVisaApplication}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Expiration date of your visa (if you have one) (Winter)</Form.Label>
                  <Datetime
                    timeFormat={false}
                    dateFormat={DATE_FORMAT_WEB}
                    closeOnSelect
                    utc={true}
                    inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: isWorkApplicationSubmitted }}
                    value={DateTimeFormater(repWorkApplication.repWinterForm.expiringVisaDate, null, DATE_FORMAT_WEB)}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {
                          ...repWorkApplication.repWinterForm,
                          expiringVisaDate: DateTimeFormater(e, null, DATE_FORMAT_ISO),
                        },
                      });
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    If you are in a relationship and would like to request to be planned in the same Destination as your
                    partner, please confirm your partners full name (as per their Passport) in the box below{' '}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={repWorkApplication.repWinterForm.fullPartnerName || ''}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {
                          ...repWorkApplication.repWinterForm,
                          fullPartnerName: e.currentTarget.value,
                        },
                      });
                    }}
                    disabled={isWorkApplicationSubmitted}
                  />
                  <Form.Text className="text-muted">
                    Fullname ( as per passport ) of partner if you request to work together
                  </Form.Text>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>

        <Card style={cardStyle}>
          <Card.Header>
            <i className="fa fa-snowflake-o mr-2" />
            <i className="fa fa-repeat mr-2" />
            Change of Role Request
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>
                    I would like to be considered for a change of role for winter {requiredField()}
                  </Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        value="Yes"
                        checked={repWorkApplication.repWinterForm.considerChangeRole === 'Yes'}
                        onChange={(e) => {
                          setRepWorkApplication({
                            ...repWorkApplication,
                            repWinterForm: {
                              ...repWorkApplication.repWinterForm,
                              considerChangeRole: e.currentTarget.value,
                            },
                          });
                          setRepWorkApplicationError({
                            ...repWorkApplicationError,
                            repWinterForm: {
                              ...repWorkApplicationError.repWinterForm,
                              considerChangeRole: null,
                            },
                          });
                        }}
                        disabled={isWorkApplicationSubmitted}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        value="No"
                        checked={repWorkApplication.repWinterForm.considerChangeRole === 'No'}
                        onChange={(e) => {
                          setRepWorkApplication({
                            ...repWorkApplication,
                            repWinterForm: {
                              ...repWorkApplication.repWinterForm,
                              considerChangeRole: e.currentTarget.value,
                              considerChangeRoleJobTitles: [],
                            },
                          });
                          setRepWorkApplicationError({
                            ...repWorkApplicationError,
                            repWinterForm: {
                              ...repWorkApplicationError.repWinterForm,
                              considerChangeRole: null,
                            },
                          });
                        }}
                        disabled={isWorkApplicationSubmitted}
                      />
                      {repWorkApplicationError.repWinterForm.considerChangeRole && (
                        <Form.Text className="form-text-red">
                          {repWorkApplicationError.repWinterForm.considerChangeRole}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  as={Col}
                  hidden={
                    repWorkApplication.repWinterForm.considerChangeRole === 'No' ||
                    !repWorkApplication.repWinterForm.considerChangeRole
                  }
                >
                  <Form.Label>
                    In winter I would be happy to work in the following job titles (multiple selection)
                  </Form.Label>
                  <Select
                    isMulti
                    options={WinterChangeRoleJobTitle}
                    onChange={(selectedOptions) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repWinterForm: {
                          ...repWorkApplication.repWinterForm,
                          considerChangeRoleJobTitles: selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [],
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repWinterForm: {
                          ...repWorkApplicationError.repWinterForm,
                          considerChangeRoleJobTitles: null,
                        },
                      });
                    }}
                    value={(repWorkApplication.repWinterForm.considerChangeRoleJobTitles || []).map((jobTitle) =>
                      WinterChangeRoleJobTitle.find((option) => option.value === jobTitle)
                    )}
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repWinterForm.considerChangeRoleJobTitles && (
                    <Form.Text className="form-text-red">
                      {repWorkApplicationError.repWinterForm.considerChangeRoleJobTitles}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default RepWinterForm;
