export const ValidateManagerWorkApplicationForm = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError,
  isLongHaul
) => {
  // console.log(managerWorkApplication);
  // console.log(managerWorkApplicationError);
  // console.log(isLongHaul);

  if (isLongHaul) {
    return ManagerLongHaulFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else {
    return ManagerFormValidation(managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError);
  }
};

const ManagerFormValidation = (managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError) => {
  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedWinterAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedWinterAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedWinterAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedSummerAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedSummerAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedSummerAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  // if (!managerWorkApplication.managerCommentsForm.managerSupportsAdp) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       managerSupportsAdp: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  // if (!managerWorkApplication.managerCommentsForm.isPerformanceManagementInprocess) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       isPerformanceManagementInprocess: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  // if (!managerWorkApplication.managerCommentsForm.isManagerValidatesSourceMarkets) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       isManagerValidatesSourceMarkets: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  // if (
  //   !managerWorkApplication.managerCommentsForm.managerValidatesSourceMarketsComments &&
  //   managerWorkApplication.managerCommentsForm.isManagerValidatesSourceMarkets === 'No'
  // ) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       managerValidatesSourceMarketsComments: 'Please select write comments',
  //     },
  //   });
  //   return false;
  // }

  // if (!managerWorkApplication.managerCommentsForm.collegueWorkingConcept?.length) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       collegueWorkingConcept: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  // if (!managerWorkApplication.managerCommentsForm.managerColleagueStrenghts?.length) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       managerColleagueStrenghts: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  // if (!managerWorkApplication.managerCommentsForm.managerDevelopmenTareas?.length) {
  //   setManagerWorkApplicationError({
  //     ...managerWorkApplicationError,
  //     managerCommentsForm: {
  //       ...managerWorkApplicationError.managerCommentsForm,
  //       managerDevelopmenTareas: 'Please select option',
  //     },
  //   });
  //   return false;
  // }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};

const ManagerLongHaulFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerLongHaulForm.isManagerSupportApplication) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerSupportApplication: 'Please select option',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerLongHaulForm.comments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        comments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};
