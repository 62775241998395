import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';

import RestClient from 'Services/RestClientService';
import JobAssignmentCard from 'Components/Cards/JobAssignmentCard';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const JobAssigmentView = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.currentUser.upn);
  const jobFamiliesWork = useSelector((state) => state.settings.jobFamiliesWork);
  const destinationsWork = useSelector((state) => state.settings.acceptDeclineCountry);
  const seasonsOpen = useSelector((state) => state.settings.seasonsOpen);

  const [assigmentsList, setAssigmentsList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [refreshParentComponent, setParentRefreshComponent] = useState(0);

  useEffect(() => {
    dispatch(setLoader(true));
    if (!currentUserEmail) return;
    RestClient.Get(`staff/getbyemail/${currentUserEmail}`)
      .then((staffInfo) => {
        // console.log(staffInfo);
        if (!staffInfo) return;
        setEmployee(staffInfo);
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, [currentUserEmail]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;
    RestClient.Get(`positionAssign/byStaffId/${employee.staffID}`).then((response) => {
      console.log(response);
      if (!response || !response.positionAssignList) return;

      let filteredAssignments = response.positionAssignList;

      // filter by job families and destinations
      if (jobFamiliesWork !== '') {
        filteredAssignments = filteredAssignments.filter((assign) => {
          return jobFamiliesWork.split(',').some((element) => element.toLowerCase() === assign.jobFamily.toLowerCase());
        });
      }
      if (destinationsWork !== '') {
        filteredAssignments = filteredAssignments.filter((assign) => {
          return destinationsWork
            .split(',')
            .some((element) => element.toLowerCase() === assign.destination.toLowerCase());
        });
      }

      // filter all assignment that are pending
      filteredAssignments = filteredAssignments.filter((assign) => {
        return assign.status?.toLowerCase() !== 'pending' && assign.accept?.toLowerCase() !== 'pending';
      });

      // filter all assignment by season
      if (seasonsOpen !== '') {
        filteredAssignments = filteredAssignments.filter((assign) => {
          return seasonsOpen.split(',').some((element) => element.toLowerCase() === assign.season.toLowerCase());
        });
      }

      setAssigmentsList(filteredAssignments);
    });
  }, [employee, refreshParentComponent]);

  return (
    <>
      <h1 style={{ marginTop: '0.5%' }}>Job Assigments</h1>
      {assigmentsList && assigmentsList.length > 0 ? (
        assigmentsList?.map((assignemnt, index) => {
          // console.log(assignemnt);
          return (
            <JobAssignmentCard
              key={index}
              assignemnt={assignemnt}
              employee={employee}
              setRefreshParentComponent={setParentRefreshComponent}
              refreshParentComponent={refreshParentComponent}
            />
          );
        })
      ) : (
        <>
          <hr style={{ marginTop: '1%' }}></hr>
          <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%' }}>
            <h4>
              <i className="fa fa-search" /> Can´t find any assigment for you
            </h4>
          </Row>
          <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%' }}>
            <small>Seems like you are not assigned to any position yet !.</small>
          </Row>
        </>
      )}
    </>
  );
};

export default JobAssigmentView;
