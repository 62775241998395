import React, { useEffect, useState } from 'react';
import { Card, Form, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import RestClient from 'Services/RestClientService';

const EmployeeLanguagesCard = (props) => {
  const { employee } = props;

  const languagesOptionList = useSelector((state) => state.masterData.languages);
  const languageLevelsOptionList = useSelector((state) => state.masterData.languageLevels);

  const [staffLanguages, setStaffLanguages] = useState([]);
  const [refreshComponent, setRefreshComponent] = useState(0);

  useEffect(() => {
    RestClient.Get(`staff/languages/${employee.staffID}`)
      .then((response) => {
        setStaffLanguages(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [employee, refreshComponent]);

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-flag mr-2"></i>Language
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            {languagesOptionList.length > 0 &&
              languagesOptionList.map((language, index) => {
                if (language.value === '20') return <Form.Group key={language.value + index}></Form.Group>;
                return (
                  <Form.Group as={Col} index={index} key={language.value + index} lg={3}>
                    <Form.Label>{language.label}</Form.Label>
                    <Select
                      onChange={(e) => {
                        const rq = {
                          staffID: employee.staffID,
                          languageID: language.value,
                          languageLevelID: e.value,
                        };
                        RestClient.Post('staff/languages', rq)
                          .then(() => {
                            // console.log(response);
                          })
                          .then(() => {
                            setRefreshComponent(refreshComponent + 1);
                          });
                      }}
                      options={languageLevelsOptionList}
                      value={
                        staffLanguages?.find((x) => x.languageId == language.value) && {
                          value: staffLanguages?.find((x) => x.languageId == language.value)?.languageLevelId,
                          label: staffLanguages?.find((x) => x.languageId == language.value)?.languageLevelName,
                        }
                      }
                      isClearable
                    />
                  </Form.Group>
                );
              })}
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EmployeeLanguagesCard;
