export const preferences_for_winter_options = [
  { label: 'Would like to work all winter October to April', value: 'Would like to work all winter October to April' },
  { label: 'Would like to work part winter only', value: 'Would like to work part winter only' },
  {
    label: 'I do not wish to work in winter but would like to return for summer',
    value: 'I do not wish to work in winter but would like to return for summer',
  }, // hide only winter
  {
    label: 'I do not wish to work in winter and plan to resign at the end of summer',
    value: 'I do not wish to work in winter and plan to resign at the end of summer',
  }, // hide all
];

export const most_important_options = [
  { value: 'Period of work', label: 'Period of work' },
  { value: 'Destination', label: 'Destination' },
  { value: 'Job Family', label: 'Job Family' },
  { value: 'Work with my partner', label: 'Work with my partner' },
  { value: 'My current Ski application', label: 'My current Ski application' },
  { value: 'My current secondment application', label: 'My current secondment application' },
  { value: 'My Long Haul Application', label: 'My Long Haul Application' },
];

export const preferences_for_summer_options = [
  { label: 'Would like to work all summer April to October', value: 'Would like to work all summer April to October' },
  { label: 'Would like to work part summer only', value: 'Would like to work part summer only' },
];

export const most_important_summer_options = [
  {
    value: 'Period of work',
    label: 'Period of work',
  },
  {
    value: 'Destination',
    label: 'Destination',
  },
  {
    value: 'Job Family',
    label: 'Job Family',
  },
  {
    value: 'Work with my partner',
    label: 'Work with my partner',
  },
  {
    value: 'My Long Haul Application',
    label: 'My Long Haul Application',
  },
];

export const customer_nationalities = [
  { value: 'English Speaking', label: 'English Speaking' },
  { value: 'Dutch Speaking', label: 'Dutch Speaking' },
  { value: 'Belgian Speaking', label: 'Belgian Speaking' },
  { value: 'French Speaking', label: 'French Speaking' },
  { value: 'German Speaking', label: 'German Speaking' },
  { value: 'Polish Speaking', label: 'Polish Speaking' },
  { value: 'Swedish Speaking', label: 'Swedish Speaking' },
  { value: 'Danish Speaking', label: 'Danish Speaking' },
  { value: 'Finnish Speaking', label: 'Finnish Speaking' },
  { value: 'Norwegian Speaking', label: 'Norwegian Speaking' },
  { value: 'Czech Speaking', label: 'Czech Speaking' },
  { value: 'Hungarian Speaking', label: 'Hungarian Speaking' },
];

export const current_tuimm_options = [
  { value: 'Exceptional (advocate)', label: 'Exceptional (advocate)' },
  { value: 'Overachiever (role model)', label: 'Overachiever (role model)' },
  { value: 'Good job (trusted)', label: 'Good job (trusted)' },
  { value: 'Needs Development (needs some work)', label: 'Needs Development (needs some work)' },
  { value: 'Underperforming', label: 'Underperforming' },
  { value: 'Late starter (too early to rate)', label: 'Late starter (too early to rate)' },
];

export const current_objective_performance_options = [
  {
    value: 'Exceptional/Regional Top performer (exceeding all targets)',
    label: 'Exceptional/Regional Top performer (exceeding all targets)',
  },
  { value: 'Overachieving (combined over target)', label: 'Overachieving (combined over target)' },
  { value: 'Good job (broadly on target)', label: 'Good job (broadly on target)' },
  { value: 'Needs Development (some under target)', label: 'Needs Development (some under target)' },
  { value: 'Underperforming (all under target)', label: 'Underperforming (all under target)' },
  { value: 'Late starter (not able to rate)', label: 'Late starter (not able to rate)' },
];

export const decline_options = [
  { label: 'Decline Availability Dates', value: 'Decline Availability Dates' },
  { label: 'Decline Job Title offer', value: 'Decline Job Title offer' },
  { label: 'Decline Destination offer', value: 'Decline Destination offer' },
  { label: 'Decline Change of Role', value: 'Decline Change of Role' },
  { label: 'Decline & Wish to resign', value: 'Decline & Wish to resign' },
];

export const if_collegue_working_concept = [
  { label: 'Suitable for Sensatori', value: 'Suitable for Sensatori' },
  { label: 'Suitable for Families', value: 'Suitable for Families' },
  { label: 'Suitable for Adults Only', value: 'Suitable for Adults Only' },
  { label: 'Flexible for all (Adults/Families & Sensatori)', value: 'Flexible for all (Adults/Families & Sensatori)' },
];

export const manager_colleague_strenghts_options = [
  { value: 'Seller', label: 'Seller' },
  { value: 'Great Service', label: 'Great Service' },
  { value: 'Best in a Team', label: 'Best in a Team' },
  { value: 'Highly Independent', label: 'Highly Independent' },
  { value: 'Takes Responsibility', label: 'Takes Responsibility' },
  { value: 'Problem Solving', label: 'Problem Solving' },
  { value: 'Organised', label: 'Organised' },
  { value: 'Tech Savvy', label: 'Tech Savvy' },
];

export const manager_development_areas_options = [
  { value: 'Sales', label: 'Sales' },
  { value: 'Service', label: 'Service' },
  { value: 'Team Player', label: 'Team Player' },
  { value: 'Ability to work Independently', label: 'Ability to work Independently' },
  { value: 'Problem Solving', label: 'Problem Solving' },
  { value: 'Organisation Skills', label: 'Organisation Skills' },
  { value: 'Technical Know How', label: 'Technical Know How' },
];
